import axios from 'axios'
import {
  COLLECTION_LIST_ALL_REQUEST,
  COLLECTION_LIST_ALL_SUCCESS,
  COLLECTION_LIST_ALL_FAIL,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_UPDATE_REQUEST,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  CREATE_COLLECTION_REQUEST,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAIL,
  GET_COLLECTION_REQUEST,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_FAIL,
} from '../constants/collectionConstants'
import { logout } from './userActions'
const apiUrl = process.env.REACT_APP_API_URL;
export const listAllCollections = () => async(dispatch) => {
  try {
    dispatch({type: COLLECTION_LIST_ALL_REQUEST})
    const {data} = await axios.get(`${apiUrl}/api/collection`);
    dispatch({
      type: COLLECTION_LIST_ALL_SUCCESS,
      payload: data
    })
  } catch (error) {
    dispatch({
      type: COLLECTION_LIST_ALL_FAIL,
      payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
    })
  }
}

export const listcollectionList = (id) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_LIST_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/collections/${id}`)

    dispatch({
      type: COLLECTION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COLLECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listcollectionListBySlug = (id) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_LIST_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/collections/slug/${id}`)

    dispatch({
      type: COLLECTION_LIST_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: COLLECTION_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCollection = (collection) => async (dispatch, getState) => {
    try {
      dispatch({
        type: COLLECTION_UPDATE_REQUEST,
      })

      const {
        userLogin: { userInfo },
      } = getState()

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
      }

      const { data } = await axios.put(
        `${apiUrl}/api/collections/${collection._id}`,
        collection,
        config
      )

      dispatch({
        type: COLLECTION_UPDATE_SUCCESS,
        payload: data,
      })
      dispatch({ type: COLLECTION_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      if (message === 'Not authorized, token failed') {
        dispatch(logout())
      }
      dispatch({
        type: COLLECTION_UPDATE_FAIL,
        payload: message,
      })
    }
  }

export const listParentCollections = (id) => async (dispatch) => {
    try {
      dispatch({ type: COLLECTION_LIST_REQUEST })
  
      const { data } = await axios.get(`${apiUrl}/api/collections/all/parentcollections`)
  
      dispatch({
        type: COLLECTION_LIST_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: COLLECTION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// for creating a collection
export const createCollection = (collection) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_COLLECTION_REQUEST });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`${apiUrl}/api/collection`, collection, config);

    dispatch({
      type: CREATE_COLLECTION_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_COLLECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// for editing a collection
export const getCollectionById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_COLLECTION_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/collection/${id}`);

    dispatch({
      type: GET_COLLECTION_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_COLLECTION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const putCategoryById = () => async () => {

}

export const listProductsInCategoryById = () => async () => {

}
