import React from 'react'
// import { products } from '../../data/product'

const Tab = ({ products, filteredProducts }) => {
  const displayProducts =
    filteredProducts.length > 0 ? filteredProducts : products.products

  return (
    <>
      {displayProducts?.map((product, i) => {
        return (
          <div
            key={i}
            className='col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 collectionCard'
          >
            <div className='card'>
              <span className='heart'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='w-6 h-6'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z'
                  ></path>
                </svg>
              </span>
              <a href={`/product/${product.slug}`}>
                <img
                  src={`/${product.image[0]}`}
                  className='card-img-top'
                  alt='...'
                />
              </a>
              <div className='card-body'>
                <div className='priceBody row'>
                  <div className='col-12 col-sm-12'>
                    <h5 className='card-title productTitle'>{product.name}</h5>
                  </div>
                  <div className='col-12 col-sm-12'>
                    <p className='card-text pricee'>₹ {product.price}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Tab
