import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const PrivateRoute = ({ component: Component, adminOnly, ...rest }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  return (
    <Route
      {...rest}
      render={(props) =>
        userInfo ? (
          // If adminOnly is true, only allow access if the user is an admin
          adminOnly && !userInfo.isAdmin ? (
            <Redirect to='/login' />
          ) : (
            <Component {...props} />
          )
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  )
}

export default PrivateRoute
