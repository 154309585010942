import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import CheckoutSteps from '../components/CheckoutSteps'
import { savePaymentMethod } from '../actions/cartActions'

const PaymentScreen = ({ history }) => {
  const cart = useSelector((state) => state.cart)
  const { shippingAddress } = cart

  if (!shippingAddress.address) {
    history.push('/shipping')
  }

  const [paymentMethod, setPaymentMethod] = useState('Razorpay')

  const dispatch = useDispatch()

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(savePaymentMethod(paymentMethod))
    history.push('/placeorder')
  }

  return (
    <FormContainer>
      <CheckoutSteps step1 step2 />
      <h1>Payment Method</h1>
      <form onSubmit={submitHandler}>
        <div className='mb-3'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              value='razorpay'
              checked
              name='paymentMethod'
              id='razorpay'
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label className='form-check-label' htmlFor='razorpay'>
              Razorpay(Debit)
            </label>
          </div>
        </div>

        <div className='mb-3'>
          <div className='form-check'>
            <input
              className='form-check-input'
              type='radio'
              value='cod'
              name='paymentMethod'
              id='cod'
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label className='form-check-label' htmlFor='cod'>
              Cash On Delivery (COD)
            </label>
          </div>
        </div>

        <button type='submit' className='btn btn-primary'>
          Continue
        </button>
      </form>
    </FormContainer>
  )
}

export default PaymentScreen
