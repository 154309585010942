const InputField = ({ label, type = 'text', name, value, onChange, placeholder, autoComplete = 'off', className = 'form-control', id }) => {
    return (
        <div className="mb-3">
            <label htmlFor={id} className="form-label">{label}</label>
            <input
                type={type}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                autoComplete={autoComplete}
                className={className}
                id={id}
            />
        </div>
    );
};

export default InputField;