import React from 'react'
import Inventory from './create/Inventory'
import Category from './create/Category'
import ProductInfo from './create/ProductInfo'
import Media from './create/Media'
import Pricing from './create/Pricing'
import Seo from './create/Seo'
import Brand from './create/Brand'
import Highlights from './create/Highlights'
import Tax from './create/Tax'
import Options from './create/Options'

const Detail = ({
  productId,
  setBrandName,
  dataChangeHandler,
  setBrandData,
  brandData,
  groupId,
  setGroupId,
  sku,
  setName,
  setSKU,
  seo,
  handleInputChange,
  name,
  slug,
  description,
  price,
  wasPrice,
  quantity,
  setSlug,
  setPrice,
  setWasPrice,
  setDescription,
  setQuantity,
  setIsActive,
  uploadFileHandler,
  media,
  setMedia,
  isActive,
  highlights,
  setHighlights,

  pId,
  optionsDataNew,
  setOptionsDataNew,
  handleTaxChange,
  
  tax,
}) => {

  return (
    <section className='product-admin'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8'>
            <ProductInfo
              dataChangeHandler={dataChangeHandler}
              setName={setName}
              setSlug={setSlug}
              setDescription={setDescription}
              name={name}
              slug={slug}
              description={description}
              setGroupId={setGroupId}
              groupId={groupId}
            />

            <Media media={media} setMedia={setMedia} uploadFileHandler={uploadFileHandler} productId={productId} />

            <Pricing
              setPrice={setPrice}
              price={price}
              setWasPrice={setWasPrice}
              wasPrice={wasPrice}
              dataChangeHandler={dataChangeHandler}
            />

            <Inventory
              setSKU={setSKU}
              setQuantity={setQuantity}
              sku={sku}
              quantity={quantity}
            />

            <Seo handleInputChange={handleInputChange} seo={seo} />

            <Options 
              slug={slug}
              pId={pId}
              optionsDataNew={optionsDataNew}
              setOptionsDataNew={setOptionsDataNew}
              setGroupId={setGroupId}
              groupId={groupId}
            />

            <Tax handleTaxChange={handleTaxChange} tax={tax} />
            
            <Highlights highlights={highlights} setHighlights={setHighlights} />

          </div>

          <div className='col-lg-4'>
            <div className='card mb-3'>
              <div className='card-header'>Product Status</div>
              <div className='card-body'>

              <select
                className='form-select'
                aria-label='Default select example'
                onChange={(e) => setIsActive(e.target.value)}
                name='isActive'
                defaultValue={isActive ? 'true' : 'false'}
              >
                {
                  isActive === true ? 

                  <>

                  <option value='true' selected>Active</option>
                <option value='false'>Inactive</option>
                </>

                :

                <><option value='true'>Active</option>
                <option value='false' selected>Inactive</option></>

                }
                
              </select>

              </div>
            </div>

            <div className='card'>
              <div className='card-header'>Product</div>
              <div className='card-body'>

                <Brand
                  setBrandData={setBrandData}
                  brandData={brandData}
                  setBrandName={setBrandName}
                />

                <Category productId={productId} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Detail
