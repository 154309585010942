import React from 'react'

function Footer() {
  return (
      <footer>
        <section className='footer-section'>
          <div className='container-fluid copyright'>
            <div className='row text-center'>
              <div className='col-12'>
                <p>© 2024 TelefoneDeal. All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </section>
      </footer>
  )
}

export default Footer
