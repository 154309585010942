export const COLLECTION_LIST_ALL_REQUEST = 'COLLECTION_LIST_ALL_REQUEST'
export const COLLECTION_LIST_ALL_SUCCESS = 'COLLECTION_LIST_ALL_SUCCESS'
export const COLLECTION_LIST_ALL_FAIL = 'COLLECTION_LIST_ALL_FAIL'

export const COLLECTION_LIST_REQUEST = 'COLLECTION_LIST_REQUEST'
export const COLLECTION_LIST_SUCCESS = 'COLLECTION_LIST_SUCCESS'
export const COLLECTION_LIST_FAIL = 'COLLECTION_LIST_FAIL'

export const COLLECTION_UPDATE_REQUEST = 'COLLECTION_UPDATE_REQUEST'
export const COLLECTION_UPDATE_SUCCESS = 'COLLECTION_UPDATE_SUCCESS'
export const COLLECTION_UPDATE_FAIL = 'COLLECTION_UPDATE_FAIL'

export const CREATE_COLLECTION_REQUEST = 'CREATE_COLLECTION_REQUEST'
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS'
export const CREATE_COLLECTION_FAIL = 'CREATE_COLLECTION_FAIL'

export const GET_COLLECTION_REQUEST = 'GET_COLLECTION_REQUEST'
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS'
export const GET_COLLECTION_FAIL = 'GET_COLLECTION_FAIL'

