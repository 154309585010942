export function formatCurrency(amount) {
    return amount.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
    });
}

export function calculateDiscountPercentage(originalPrice, discountedPrice) {
    const discountPercentage = ((originalPrice - discountedPrice) / originalPrice) * 100;
    return discountPercentage.toFixed(0);
}