import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  listCategoryDetail,
  listCategoryProducts,
} from '../../actions/categoryActions'
import Filter from '../../components/category/Filter'
import Tab from '../../components/collections/Tab'
import styles from '../../styles/CategoryScreen.module.css'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;
const CategoryScreen = ({ history, match }) => {
  const dispatch = useDispatch()
  let queryParams = {
    priceRange: '',
    color: '',
    size: '',
    brand: '',
  }

  const [brands, setBrands] = useState([])
  const [selectedBrands, setSelectedBrands] = useState([])

  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState([]);
  const [isPriceSelected, isSetPriceSelected] = useState(false);
  // const [sliderValue, setSliderValue] = useState(40000);
  const sliderValue = 40000;
  const [colors, setColors] = useState([]);

  const categoryDetail = useSelector((state) => state.categoryDetail)
  const { loading, error, category } = categoryDetail

  const categoryProducts = useSelector((state) => state.categoryProducts)
  const {
    loading: productsLoading,
    error: productsError,
    products,
  } = categoryProducts

  const priceSelect = document.getElementById('priceSelect')
  const priceRange = document.getElementById('priceRange')

  const handlePriceSliderChange = (e) => {
    priceSelect.value = e.target.value
    handlePriceRangeChange(e)
  }

  const handlePriceRangeChange = (e) => {
    const selectedValue = parseInt(e.target.value, 10)
    setSelectedPriceRange(selectedValue.toString())
    priceRange.value = e.target.value

    const updatedQueryParams = {
      ...queryParams,
      priceRange: `100-${selectedValue}`,
      color: selectedColors,
      brand: selectedBrands,
    }
    const maxPriceRange = {
      ...queryParams,
      priceRange: '',
      color: selectedColors,
      brand: selectedBrands,
    }

    selectedValue !== 40000
      ? isSetPriceSelected(true)
      : isSetPriceSelected(false)

    if (selectedValue === 40000) {
      dispatch(listCategoryProducts(match.params.id, maxPriceRange))
    } else {
      dispatch(listCategoryProducts(match.params.id, updatedQueryParams))
    }
  }

  const handleBrandCheckboxChange = (e, brand) => {
    const checked = e.target.checked
    setSelectedBrands((prevSelectedBrands) => {
      if (checked) {
        return [...prevSelectedBrands, brand]
      } else {
        return prevSelectedBrands.filter(
          (selectedBrand) => selectedBrand !== brand
        )
      }
    })

    const updatedSelectedBrands = checked
      ? [...selectedBrands, brand]
      : selectedBrands.filter((selectedBrand) => selectedBrand !== brand)

    queryParams = {
      ...queryParams,
      brand: updatedSelectedBrands,
      color: selectedColors,
    }

    dispatch(listCategoryProducts(match.params.id, queryParams))
  }

  const handleColorCheckboxChange = (e, color) => {
    const checked = e.target.checked
    setSelectedColors((prevSelectedColors) => {
      if (checked) {
        return [...prevSelectedColors, color]
      } else {
        return prevSelectedColors.filter(
          (selectedColor) => selectedColor !== color
        )
      }
    })

    // Update the queryParams object with both color and brand properties
    queryParams = {
      ...queryParams,
      color: checked
        ? [...selectedColors, color]
        : selectedColors.filter((selectedColor) => selectedColor !== color),
      brand: selectedBrands, // Include the selectedBrands array here
    }
    dispatch(listCategoryProducts(match.params.id, queryParams))
  }

  const filteredProducts =
    products && products.products
      ? products.products.filter((product) => {
          if (
            selectedBrands.length > 0 &&
            !selectedBrands.includes(product.brandId)
          ) {
            return false
          }

          return true
        })
      : []

  useEffect(() => {
    dispatch(listCategoryDetail(match.params.id))
    dispatch(listCategoryProducts(match.params.id))
    axios
      .get(`${apiUrl}/api/category/products/filter/${match.params.id}`)
      .then((response) => {
        setBrands(response.data.brands)
        setColors(response.data.colors)
      })
      .catch((error) => {
        console.error('Error making API request:', error)
      })
  }, [dispatch])

  return (
    <>
    {/* {JSON.stringify(filteredProducts)} */}
      <main className={styles.main}>
        <section>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>Error: {error}</div>
                ) : (
                  <>
                    <h1>{category && category.name}</h1>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-3'>
                <Filter
                  handlePriceSliderChange={handlePriceSliderChange}
                  sliderValue={sliderValue}
                  selectedPriceRange={selectedPriceRange}
                  productsLoading={productsLoading}
                  productsError={productsError}
                  error={error}
                  products={products}
                  handleBrandCheckboxChange={handleBrandCheckboxChange}
                  selectedBrands={selectedBrands}
                  selectedColors={selectedColors}
                  handleColorCheckboxChange={handleColorCheckboxChange}
                  handlePriceRangeChange={handlePriceRangeChange}
                  colors={colors}
                  brands={brands}
                  isPriceSelected={isPriceSelected}
                />
              </div>
              <div className='col-lg-9'>
                <div className='row productListRow'>
                  {productsLoading ? (
                    <div>Loading...</div>
                  ) : productsError ? (
                    <div>Error: {error}</div>
                  ) : (
                    <Tab
                      products={products}
                      filteredProducts={filteredProducts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

export default CategoryScreen