import axios from 'axios'
import {
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  MENU_LIST_FAIL,
  MENU_GET_REQUEST,
  MENU_GET_SUCCESS,
  MENU_GET_FAIL,
  MENU_UPDATE_REQUEST,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAIL,
} from '../constants/menuConstants'
const apiUrl = process.env.REACT_APP_API_URL;

export const listAllMenus = () => async (dispatch) => {
  try {
    dispatch({ type: MENU_LIST_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/menu/all`)

    dispatch({
      type: MENU_LIST_SUCCESS,
      payload: data.menus,
    })
  } catch (error) {
    dispatch({
      type: MENU_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listMenuById = (id) => async (dispatch) => {
  try {
    dispatch({ type: MENU_GET_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/menu/${id}`)

    dispatch({
      type: MENU_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MENU_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateMenuById = (id, menuData) => async (dispatch) => {
  try {
    dispatch({ type: MENU_UPDATE_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(`/api/menu/update/${id}`, menuData, config)

    dispatch({
      type: MENU_UPDATE_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: MENU_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
