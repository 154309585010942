import React from 'react'
import { Link } from 'react-router-dom'
import { history } from "../../../helpers/index";

const handleBack = () => {
  history.goBack();
};


const AdminNav = (props) => {
  const { loading, name, error, errorMessage, submitHandler, update, isView, isUpdated } = props
  return (
    <>
      <nav className='navbar navbar-expand-lg bg-light sticky-top mb-4 z-3'>
        <div className='container'>
          <div>
            <a
              onClick={handleBack}
              className='btn btn-outline-primary me-3'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 me-2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3'
                />
              </svg>
              Go Back
            </a>

            <a className='navbar-brand'></a>
          </div>

          {
            isView ?
            <div>
              <a href='' className='btn btn-outline'>
                View
              </a>
            </div>
            :
            null
          }

          <div>
            {
              isUpdated ?
              <button
                onClick={(e) => update(e)}
                className='btn btn-success'
                type='submit'
              >
                {loading ? 'Updating...' : 'Update'}
              </button>
              :
              <button
                onClick={(e) => update(e)}
                className='btn btn-disabled'
                type='button'
              >
                {'Update'}
              </button>
            }
          </div>

          

          

        </div>
      </nav>
      {error ? (
        <div className='container'>
          <div
            className='alert alert-danger d-flex align-items-center'
            role='alert'
          >
            <div>{errorMessage}</div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AdminNav
