import React, { useState, useEffect } from 'react'

const NewFeatures = ({ setAllFeatures, theFeatures, name }) => {
  const [features, setFeatures] = useState([])
  const [newFeature, setNewFeature] = useState({
    heading: '',
    description: '',
  })
  const [updateData, setUpdateData] = useState('')

  // add feature
  const addFeature = () => {
    if (newFeature) {
      let num = Date.now() + Math.random()
      let newEntry = {
        id: num,
        heading: newFeature.heading,
        description: newFeature.description,
      }
      setFeatures([...features, newEntry])
      // SENDING BACK TO STORING DATA
      setAllFeatures([...features, newEntry])

      setNewFeature('')
    }
  }

  // get update feature
  const updateFeatureHandler = (e) => {
    setUpdateData({ ...updateData, [e.target.name]: e.target.value })
  }

  // update the feature
  const updateTheFeature = (e) => {
    let filterRecords = [...features].filter(
      (item) => item.id !== updateData.id
    )
    let updatedObject = [...filterRecords, updateData]
    setFeatures(updatedObject)
    // SENDING BACK TO STORING DATA
    setAllFeatures(updatedObject)
    setUpdateData('')
  }

  // cancel update
  const cancelFeature = (id) => {
    setUpdateData('')
  }

  // delete feature
  const deleteFeature = (id) => {
    let newFeatures = features.filter((feature) => feature.id !== id)
    setFeatures(newFeatures)
    // SENDING BACK TO STORING DATA
    setAllFeatures(newFeatures)
  }

  const createNewFeatureHandler = (e) => {
    setNewFeature({ ...newFeature, [e.target.name]: e.target.value })
  }

  useEffect(() => {
    // setAllFeatures(features)
    setFeatures(theFeatures)
    // SENDING BACK TO STORING DATA
    setAllFeatures(theFeatures)
  }, [theFeatures])

  return (
    <>
      <div>
        <h3>Update feature</h3>
        <input
          type='text'
          className='form-control mb-2'
          name='heading'
          placeholder='Feature Heading'
          value={updateData && updateData.heading}
          onChange={(e) => updateFeatureHandler(e)}
        />
        <input
          type='text'
          className='form-control mb-2'
          name='description'
          placeholder='Feature Description'
          value={updateData && updateData.description}
          onChange={(e) => updateFeatureHandler(e)}
        />
        <button
          type='button'
          onClick={updateTheFeature}
          className='btn btn-success mb-2 me-2'
        >
          Update Feature
        </button>
        <button
          type='button'
          onClick={cancelFeature}
          className='btn btn-danger mb-2'
        >
          Cancel Update
        </button>
      </div>
      <div>
        <h3>Create new feature</h3>
        <input
          type='text'
          className='form-control mb-2'
          name='heading'
          placeholder='Feature Heading'
          onChange={(e) => createNewFeatureHandler(e)}
        />
        <input
          type='text'
          className='form-control mb-2'
          name='description'
          placeholder='Feature Description'
          onChange={(e) => createNewFeatureHandler(e)}
        />
        <button
          type='button'
          onClick={addFeature}
          className='btn btn-success mb-2'
        >
          Add Feature
        </button>
      </div>

      {features && features.length ? '' : 'No features added'}

      {features
        ?.sort((a, b) => (a.id > b.id ? 1 : -1))
        ?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className='card'>
                <span
                  className='imagePosition delete'
                  onClick={() => deleteFeature(item.id)}
                >
                  Delete
                </span>
                <span
                  className='imagePosition update'
                  onClick={() =>
                    setUpdateData({
                      id: item.id,
                      heading: item.heading,
                      description: item.description,
                    })
                  }
                >
                  Update
                </span>
                <div className='card-body'>{item.heading}</div>
                <div className='card-body'>{item.description}</div>
              </div>
            </React.Fragment>
          )
        })}
    </>
  )
}

export default NewFeatures
