import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from './reducers/productReducers'

import {
  collectionListReducer,
  collectionUpdateReducer,
  collectionListAllReducer,
  getCollectionReducer,
  collectionCreateReducer
} from './reducers/collectionReducer'
import {
  categoryListReducer,
  categoryDetailReducer,
  categoryProductsReducer,
  getCategoryReducer,
  putCategoryReducer,
  createCategoryReducer,
  deleteACategoryReducer,
  listProductsInCategoryReducer,
  productsInCategoryByTagsReducer,
} from './reducers/categoryReducers'

import { cartReducer } from './reducers/cartReducers'
import {
  allMenusReducer,
  menuByIdReducer,
  updateMenuReducer,
} from './reducers/menuReducers'
import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from './reducers/userReducers'
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from './reducers/orderReducers'

import {
  homepageScreenFetchReducer,
  getCategoriesReducer
} from './reducers/homepageScreenReducers'

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,
  cart: cartReducer,
  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,
  collectionList: collectionListReducer,
  collectionUpdate: collectionUpdateReducer,
  allCollections: collectionListAllReducer,
  categoryList: categoryListReducer,
  categoryDetail: categoryDetailReducer,
  categoryProducts: categoryProductsReducer,
  getCategory: getCategoryReducer,
  putCategory: putCategoryReducer,
  categoryCreate: createCategoryReducer,
  deleteACategory: deleteACategoryReducer,
  listProductsInCategory: listProductsInCategoryReducer,
  productsInCategoryByTags: productsInCategoryByTagsReducer,
  allMenus: allMenusReducer,
  menuById: menuByIdReducer,
  updateMenu: updateMenuReducer,
  homepageScreenFetch: homepageScreenFetchReducer,
  getCategories: getCategoriesReducer,
  getCollection: getCollectionReducer,
  collectionCreate: collectionCreateReducer
})

const cartItemsFromStorage = localStorage.getItem('cartItems')
  ? JSON.parse(localStorage.getItem('cartItems'))
  : []

const userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

const shippingAddressFromStorage = localStorage.getItem('shippingAddress')
  ? JSON.parse(localStorage.getItem('shippingAddress'))
  : {}

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
