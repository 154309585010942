import React from 'react';
import { Link } from 'react-router-dom';

const AdminNavbar = ({changes,discard,store,loading}) => {
  return (
    <nav className='navbar navbar-expand-lg bg-light sticky-top mb-4 z-1'>
        <div className='container'>
          <div>
            <Link to='/admin/menus' className='btn btn-outline-primary me-3'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 me-2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3'
                />
              </svg>
              Go Back
            </Link>

            <a className='navbar-brand'></a>
          </div>

          {changes ? (
            <div>
              <button
                onClick={discard}
                className='btn btn-outline-secondary me-2'
                type='submit'
              >
                Discard
              </button>

              <button onClick={store} className='btn btn-success' type='submit'>
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          ) : (
            <div>
              <button
                onClick={store}
                disabled
                className='btn btn-primary-secondary'
                type='submit'
              >
                {loading ? 'Saving...' : 'Save'}
              </button>
            </div>
          )}
        </div>
      </nav>
  )
}

export default AdminNavbar