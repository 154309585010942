import React, { useState, useEffect } from 'react';
import axios from 'axios'
import Offcanvas from '../../../components/common/Offcanvas';
import { useSelector } from 'react-redux'

const MobileMenu = ({ match }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
    
    const menuId = match.params.id;
    const [menu,setMenu] = useState([])
    // for editing 
    const [changes, setChanges] = useState(false);
    const [menuDataToEdit, setMenuDataToEdit] = useState(null);

    const editHandler = (menu) => {
      setMenuDataToEdit(menu);
    };

    const addOrUpdatePrimaryMenu = async (primaryMenu) => {
      try {
    
        const formData = new FormData();
        formData.append('menuName', primaryMenu.menuName);
        formData.append('menuURL', primaryMenu.menuURL);
        
        // Append menuId (primaryMenu._id) to the form data for updates
        if (primaryMenu._id) {
          formData.append('submenuId', primaryMenu._id); // Add the menu ID for updating
        }
    
        // Check if there's a featured image, and append it to the form data
        if (primaryMenu.featuredImage && primaryMenu.featuredImage instanceof File) {
          formData.append('featuredImage', primaryMenu.featuredImage); // New image file
        } else if (primaryMenu.featuredImage && typeof primaryMenu.featuredImage === 'string') {
          formData.append('existingFeaturedImage', primaryMenu.featuredImage); // Existing image URL
        }
    
        if (primaryMenu._id) {
          // Update existing menu item
          await axios.put(`${process.env.REACT_APP_API_URL}/api/mobile-menu/submenu/${menuId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        } else {
          // Add a new primary menu
          await axios.post(`${process.env.REACT_APP_API_URL}/api/mobile-menu/menu/${menuId}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
        }
    
        // After saving or updating, fetch the updated menu list to re-render the page
        fetchMenuData();
        setMenuDataToEdit(null); // Reset the form after update
        setChanges(false); // Reset any change state if you are using one
    
      } catch (error) {
        console.error('Error adding or updating the menu:', error);
      }
    };
    
    
    

    const fetchMenuData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/mobile-menu/${menuId}`);
        setMenu(response.data);
      } catch (error) {
        console.error('Error fetching menu data:', error);
      }
    };

    const deleteHandler = async (submenuId) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
        data: {
          submenuId, // Send submenuId in the body
        },
      };
    
      if (window.confirm('Are you sure you want to delete this submenu?')) {
        try {
          // Correct URL and axios.delete syntax
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/mobile-menu/submenu/${menuId}`, config);
          
          fetchMenuData(); // Re-fetch menu data after deletion
          alert('Submenu deleted successfully');
        } catch (error) {
          console.error('Error deleting submenu:', error);
          alert('Error deleting submenu');
        }
      }
    };
    

    useEffect(() => {
      fetchMenuData();
    }, []);

    return (
        <>

        <section className='product-admin'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='card mb-3'>
                            <div className='card-header'>Menu items</div>
                                <ul className='list-group'>
                                    {
                                        menu.menuList?.map((item) => (
                                            <React.Fragment key={item._id}>
                                                <li className='list-group-item d-flex justify-content-between align-items-center'>
                                                    {item.menuName}
                                                    <div>
                                                        <button className='btn btn-warning btn-sm me-3' onClick={() => {
                                                            editHandler(item);
                                                        }} data-bs-toggle='offcanvas' href='#primaryMenuSidebar'>Edit</button>
                                                        <button className='btn btn-danger btn-sm' 
                                                        onClick={() => deleteHandler(item._id)}
                                                        >Delete</button>
                                                    </div>
                                                </li>
                                            </React.Fragment>
                                        ))
                                    }

                                <a className='d-block' data-bs-toggle='offcanvas' role='button'
                                    href='#primaryMenuSidebar' aria-controls='primaryMenuSidebar'>
                                    <li className='list-group-item d-flex editMenu bg-success text-light justify-content-center'>
                                      Add New
                                    </li>
                                </a>

                                </ul>
                            </div>
                    </div>
                </div>
            </div>
        </section>

        <Offcanvas
            setChanges={setChanges}
            id="primaryMenuSidebar"
            canvasLabel="Edit Primary Menu"
            onAdd={addOrUpdatePrimaryMenu}
            menuDataToEdit={menuDataToEdit}
        />

        </>
    );
}

export default MobileMenu