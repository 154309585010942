import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createCollection } from '../../../actions/collectionAction';
import { Toast } from "../../../services/Toast";
import { useHistory } from 'react-router-dom';

const CollectionCreateScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const collectionCreate = useSelector((state) => state.collectionCreate);
  const { loading, error, success,collection } = collectionCreate;

  const [collectionName, setCollectionName] = useState('')
  const [slug, setSlug] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])

  const handlePaste = (event) => {
    event.preventDefault()

    const pastedValue = event.clipboardData.getData('text')

    const transformedValue = pastedValue
      .replace(/ {2,}/g, ' ') // Replace double spaces with a single space
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[()]/g, '') // Remove brackets
      .toLowerCase() // Convert to lowercase
      .replace(/,/g, '') // Delete commas

    // Update the slug state with the transformed value

    setSlug(transformedValue)
  }

  function tagsHandler(e) {
    const inputTags = e.target.value.split(',')
    setTags(inputTags.map((tag) => tag.trim()))
  }

  function createCollectionHandler() {
    const collection = {
      name: collectionName,
      slug: slug,
      description: description,
      tags: tags,
    }

    dispatch(createCollection(collection));
  }

  function removeTag(index) {
    const updatedTags = [...tags]
    updatedTags.splice(index, 1)
    setTags(updatedTags)

    // Clear tag from textarea
    const textarea = document.getElementById('tags')
    const textareaValue = textarea.value
    const tagToRemove = tags[index]
    const updatedTextareaValue = textareaValue
      .replace(tagToRemove, '')
      .replace(/,,/g, ',')
    textarea.value = updatedTextareaValue
  }

  useEffect(() => {
    if (success) {
      Toast({ type: 'success', message: 'Collection created successfully!' });
      history.push(`/admin/collection/${collection._id}/edit`);
    }
    if (error) {
      Toast({ type: 'error', message: error });
    }
  }, [success, error]);

  return (
      <section className='mt-5 mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='mb-1'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input
                      onChange={(e) => setCollectionName(e.target.value)}
                      type='text'
                      autoComplete='off'
                      name='name'
                      className='form-control'
                      id='name'
                      placeholder='Collection Name'
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='slug' className='form-label'>
                      Slug
                    </label>
                    <input
                      onPaste={handlePaste}
                      onChange={(e) => setSlug(e.target.value)}
                      type='text'
                      name='slug'
                      className='form-control'
                      id='slug'
                      placeholder='Collection Slug'
                      defaultValue={slug}
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='description' className='form-label'>
                      Description
                    </label>
                    <input
                      onChange={(e) => setDescription(e.target.value)}
                      type='text'
                      autoComplete='off'
                      name='description'
                      className='form-control'
                      id='description'
                      placeholder='A Description'
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='tags' className='form-label'>
                      Tags
                    </label>
                    <textarea
                      className='form-control mb-2'
                      id='tags'
                      rows='3'
                      name='tags'
                      onChange={tagsHandler}
                    ></textarea>

                    <div id='tags'>
                      {tags.map((tag, index) => (
                        <button
                          key={index}
                          type='button'
                          className='btn btn-primary tags-btn'
                          onClick={() => removeTag(index)}
                        >
                          {tag}{' '}
                          <span className='badge text-bg-secondary'>X</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  <button
                    className='btn btn-success'
                    onClick={createCollectionHandler}
                  >
                    Create Collection
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default CollectionCreateScreen
