import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, Button, Row, Col } from 'react-bootstrap'

const MobileMenuScreen = () => {
  

  const [allMenu, SetAllmenu] = useState([])
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  useEffect(() => {

    const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userInfo.token}`,
        },
    }

    axios.get(`${process.env.REACT_APP_API_URL}/api/mobile-menu/all`,config).then((res) => {
      console.error(res)
      SetAllmenu(res.data)
    })

  }, [])

  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            <Row className='align-items-center'>
              <Col>
                <h1>Mobile Menus</h1>
              </Col>
             
            </Row>
            <div className='col-sm-12'>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allMenu.map((menu) => (
                    <tr key={menu._id}>
                      <td>{menu.name}</td>
                      <td>
                        <a href={`/admin/mobile-menu/edit/${menu._id}`}>
                          <Button variant='light' className='btn-sm'><i className='fas fa-edit'></i></Button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MobileMenuScreen
