import React from 'react';
import axios from 'axios';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const Media = ({ uploadFileHandler, media, productId, setMedia }) => {
  const imagePath = process.env.REACT_APP_IMAGE_URL;

  const deleteHandler = async (index, e) => {
    const singleMediaDiv = e.target.closest('.singleMediaDiv');
  
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/products/image/delete/${productId}`,
        {
          data: {
            imageIndex: index,
          },
        }
      );
  
      if (response.status === 200) {
        singleMediaDiv.classList.add('d-none');
      }
    } catch (error) {
      console.error(error);
    }
  };
  

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(media);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setMedia(items);
  };

  return (
    <div className='card mb-3'>
      <div className='card-header'>Media</div>
      <div className='card-body'>
        <div className='image-upload-wrap'>
          <input
            className='file-upload-input'
            type='file'
            name='media'
            id='image-file'
            label='Choose File'
            multiple
            onChange={(e) => uploadFileHandler(e)}
          />
          <div className='drag-text'>
            <p>Click here / Drag &amp; Drop Images here</p>
          </div>
        </div>
      </div>
      <button>Upload images</button>
      <div id='showMedia'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='media' direction="horizontal">
            {(provided) => (
              <div
                className='row'
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {media.map((img, index) => (
                  <Draggable key={index} draggableId={index.toString()} index={index}>
                    {(provided) => (
                      <div
                        className='col-sm-3 mb-4 singleMediaDiv'
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className='singleMedia'>
                          <img className='img-fluid' src={`${imagePath}/${img}`} alt='' />
                          <a onClick={(e) => deleteHandler(index, e)}>
                            <div className='imagePosition trash'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                viewBox='0 0 24 24'
                                fill='currentColor'
                                className='w-6 h-6'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M16.5 4.478v.227a48.816 48.816 0 013.878.512.75.75 0 11-.256 1.478l-.209-.035-1.005 13.07a3 3 0 01-2.991 2.77H8.084a3 3 0 01-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 01-.256-1.478A48.567 48.567 0 017.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 013.369 0c1.603.051 2.815 1.387 2.815 2.951zm-6.136-1.452a51.196 51.196 0 013.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 00-6 0v-.113c0-.794.609-1.428 1.364-1.452zm-.355 5.945a.75.75 0 10-1.5.058l.347 9a.75.75 0 101.499-.058l-.346-9zm5.48.058a.75.75 0 10-1.498-.058l-.347 9a.75.75 0 001.5.058l.345-9z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </div>
                          </a>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default Media;
