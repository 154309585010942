import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Meta from '../components/Meta'
import { listProducts } from '../actions/productActions'
import { getProducts } from '../actions/homeScreenAction'

const HomeScreen = ({ match }) => {
  const keyword = match.params.keyword

  const pageNumber = match.params.pageNumber || 1

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber))
    dispatch(getProducts())
  }, [dispatch, keyword, pageNumber])

  return (
    <main className='home'>
      <Meta />
    </main>
  )
}

export default HomeScreen
