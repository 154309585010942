import React from 'react'

const ProductInfo = ({
  dataChangeHandler,
  setName,
  setSlug,
  setDescription,
  name,
  slug,
  description,
  groupId,
  setGroupId,
}) => {
  const handlePaste = (event) => {
    event.preventDefault()

    const pastedValue = event.clipboardData.getData('text')
    const transformedValue = pastedValue
      .replace(/ {2,}/g, ' ') // Replace double spaces with a single space
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[()]/g, '') // Remove brackets
      .toLowerCase() // Convert to lowercase
      .replace(/,/g, '') // Delete commas
      .replace(/\n+/g, '') // Remove newline characters

    // Update the slug state with the transformed value
    setSlug(transformedValue)
  }

  return (
    <div className='card mb-3'>
      <div className='card-body'>
        <div className='mb-3'>
          <label htmlFor='productName' className='form-label'>
            Name
          </label>
          <input
            onChange={(e) => {
              setName(e.target.value)
              dataChangeHandler(e.target.value)
            }}
            type='text'
            autoComplete='off'
            name='name'
            className='form-control'
            id='productName'
            placeholder=''
            defaultValue={name}
          />

          {name ? '' : <p className='mb-0 input-not-filled'>Please enter the Product Name.</p>}
      
        </div>
        <div className='mb-3'>
          <label htmlFor='groupId' className='form-label'>
            Group ID
          </label>
          <input
            onChange={(e) => {
              setGroupId(e.target.value)
              dataChangeHandler(e.target.value)
            }}
            type='text'
            autoComplete='off'
            name='groupId'
            className='form-control'
            id='groupId'
            placeholder=''
            defaultValue={groupId}
          />
          {groupId ? '' : <p className='mb-0 input-not-filled'>Please enter the Product Group ID.</p>}
        </div>


        <div className='mb-3'>
          <label htmlFor='productSlug' className='form-label'>
            Slug
          </label>
          <input
            onPaste={handlePaste}
            onChange={(e) => {
              setSlug(e.target.value)
              dataChangeHandler(e.target.value)
            }}
            type='text'
            name='slug'
            defaultValue={slug}
            className='form-control'
            id='productSlug'
          />
          {slug ? '' : <p className='mb-0 input-not-filled'>Please enter the Product Slug.</p>}
        </div>
        <div className='mb-3'>
          <label htmlFor='productDescription' className='form-label'>
            Description
          </label>
          <textarea
            onChange={(e) => {
              setDescription(e.target.value)
              dataChangeHandler(e.target.value)
            }}
            className='form-control'
            name='description'
            id='productDescription'
            rows='3'
            defaultValue={description}
          ></textarea>
        </div>
      </div>
    </div>
  )
}

export default ProductInfo
