import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listMenuById } from '../../../actions/menuActions';
import InputField from '../../../components/common/InputField';
import Offcanvas from '../../../components/common/Offcanvas';
import { v4 as uuidv4 } from 'uuid';
import AdminNavbar from '../common/AdminNavbar';
import axios from 'axios';
import { Toast } from "../../../services/Toast";


export default function MenuScreen({ match }) {
    const menuId = match.params.id;
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);
    const [changes, setChanges] = useState(false);
    const discard = () => {
        window.location.reload();
    }

    const [mainMenu, setMainMenu] = useState({
        name: '',
        slug: '',
        menuList: []
    });

    const [currentPrimaryId, setCurrentPrimaryId] = useState(null);
    const [currentSecondaryId, setCurrentSecondaryId] = useState(null);
    const [menuDataToEdit, setMenuDataToEdit] = useState(null);

    const menuById = useSelector(state => state.menuById);
    const { menu } = menuById;

    useEffect(() => {
        if (menuId) {
            dispatch(listMenuById(menuId));
        }
    }, [dispatch, menuId]);

    useEffect(() => {
        if (menu) {
            const updatedMenu = {
                ...menu,
                menuList: menu.menuList || []
            };
            setMainMenu(updatedMenu);
        }
    }, [menu]);

    const handleMainMenuChange = (e) => {
        setChanges(true);
        const { name, value } = e.target;
        setMainMenu(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const addOrUpdatePrimaryMenu = (primaryMenu) => {
        setMainMenu(prevState => {
            const isExisting = prevState.menuList.some(menu => menu.id === primaryMenu.id);
            const updatedMenuList = isExisting
                ? prevState.menuList.map(menu => menu.id === primaryMenu.id ? primaryMenu : menu)
                : [...prevState.menuList, { ...primaryMenu, id: uuidv4(), secondaryMenu: [] }];
            return { ...prevState, menuList: updatedMenuList };
        });
    };

    const addOrUpdateSecondaryMenu = (secondaryMenu) => {
        setMainMenu(prevState => {
            const updatedMenuList = prevState.menuList.map(menu => {
                if (menu.id === currentPrimaryId) {
                    const isExisting = menu.secondaryMenu.some(subMenu => subMenu.id === secondaryMenu.id);
                    const updatedSecondaryMenu = isExisting
                        ? menu.secondaryMenu.map(subMenu => subMenu.id === secondaryMenu.id ? secondaryMenu : subMenu)
                        : [...menu.secondaryMenu, { ...secondaryMenu, id: uuidv4(), tertiaryMenu: [] }];
                    return { ...menu, secondaryMenu: updatedSecondaryMenu };
                }
                return menu;
            });
            return { ...prevState, menuList: updatedMenuList };
        });
    };

    const addOrUpdateTertiaryMenu = (tertiaryMenu) => {
        setMainMenu(prevState => {
            const updatedMenuList = prevState.menuList.map(primaryMenu => {
                if (primaryMenu.id === currentPrimaryId) {
                    const updatedSecondaryMenu = primaryMenu.secondaryMenu.map(secondaryMenu => {
                        if (secondaryMenu.id === currentSecondaryId) {
                            const isExisting = secondaryMenu.tertiaryMenu.some(subMenu => subMenu.id === tertiaryMenu.id);
                            const updatedTertiaryMenu = isExisting
                                ? secondaryMenu.tertiaryMenu.map(subMenu => subMenu.id === tertiaryMenu.id ? tertiaryMenu : subMenu)
                                : [...secondaryMenu.tertiaryMenu, { ...tertiaryMenu, id: uuidv4() }];
                            return { ...secondaryMenu, tertiaryMenu: updatedTertiaryMenu };
                        }
                        return secondaryMenu;
                    });
                    return { ...primaryMenu, secondaryMenu: updatedSecondaryMenu };
                }
                return primaryMenu;
            });
            return { ...prevState, menuList: updatedMenuList };
        });
    };

    const editHandler = (menu) => {
        setMenuDataToEdit(menu);
    };

    const deleteHandler = (menuId, primaryMenuId, secondaryMenuId) => {
        setChanges(true);
        setMainMenu(prevState => {
            if (secondaryMenuId) {
                const updatedMenuList = prevState.menuList.map(primaryMenu => {
                    if (primaryMenu.id === primaryMenuId) {
                        const updatedSecondaryMenu = primaryMenu.secondaryMenu.map(secondaryMenu => {
                            if (secondaryMenu.id === secondaryMenuId) {
                                return { ...secondaryMenu, tertiaryMenu: secondaryMenu.tertiaryMenu.filter(tertiaryMenu => tertiaryMenu.id !== menuId) };
                            }
                            return secondaryMenu;
                        });
                        return { ...primaryMenu, secondaryMenu: updatedSecondaryMenu };
                    }
                    return primaryMenu;
                });
                return { ...prevState, menuList: updatedMenuList };
            } else if (primaryMenuId) {
                const updatedMenuList = prevState.menuList.map(primaryMenu => {
                    if (primaryMenu.id === primaryMenuId) {
                        return { ...primaryMenu, secondaryMenu: primaryMenu.secondaryMenu.filter(secondaryMenu => secondaryMenu.id !== menuId) };
                    }
                    return primaryMenu;
                });
                return { ...prevState, menuList: updatedMenuList };
            } else {
                return {
                    ...prevState,
                    menuList: prevState.menuList.filter(primaryMenu => primaryMenu.id !== menuId)
                };
            }
        });
    };

    const store = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/menu/update/${menuId}`, mainMenu);
            console.log('Update successful:', response.data);
            Toast({ type: 'success', message: 'Menu updated!' });
        } catch (error) {
            Toast({ type: 'error', message: error.message });
        }
    };

    return (
        <>
            <AdminNavbar changes={changes} discard={discard} store={store} loading={loading} />

            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <InputField
                                label="Name"
                                value={mainMenu.name}
                                onChange={handleMainMenuChange}
                                name="name"
                                placeholder="Menu Name"
                            />
                        </div>
                        <div className='col-lg-6'>
                            <InputField
                                label="Slug"
                                value={mainMenu.slug}
                                onChange={handleMainMenuChange}
                                name="slug"
                                placeholder="Menu Slug"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <section className='product-admin'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='card mb-3'>
                                <div className='card-header'>Menu items</div>
                                <ul className='list-group'>
                                    {mainMenu.menuList.map((primaryMenu) => (
                                        <React.Fragment key={primaryMenu.id}>
                                            <li className='list-group-item d-flex justify-content-between align-items-center'>
                                                {primaryMenu.menuName}
                                                <div>
                                                    <a className='btn btn-success btn-sm me-3' data-bs-toggle='offcanvas' role='button'
                                                        href='#secondaryMenuSidebar'
                                                        aria-controls='secondaryMenuSidebar'
                                                        onClick={() => {
                                                            setCurrentPrimaryId(primaryMenu.id);
                                                            setCurrentSecondaryId(null);
                                                            setMenuDataToEdit(null); // Reset edit data
                                                        }}
                                                    >
                                                        Add
                                                    </a>
                                                    <button className='btn btn-warning btn-sm me-3' onClick={() => {
                                                        editHandler(primaryMenu);
                                                        setCurrentPrimaryId(primaryMenu.id);
                                                        setCurrentSecondaryId(null);
                                                    }} data-bs-toggle='offcanvas' href='#primaryMenuSidebar'>Edit</button>
                                                    <button className='btn btn-danger btn-sm' onClick={() => deleteHandler(primaryMenu.id)}>Delete</button>
                                                </div>
                                            </li>
                                            {primaryMenu.secondaryMenu.map((secondaryMenu) => (
                                                <React.Fragment key={secondaryMenu.id}>
                                                    <ul>
                                                        <li className='list-group-item d-flex justify-content-between align-items-center'>
                                                            {secondaryMenu.menuName}
                                                            <div>
                                                                <a className='btn btn-success btn-sm me-3' data-bs-toggle='offcanvas' role='button'
                                                                    href='#tertiaryMenuSidebar'
                                                                    aria-controls='tertiaryMenuSidebar'
                                                                    onClick={() => {
                                                                        setCurrentPrimaryId(primaryMenu.id);
                                                                        setCurrentSecondaryId(secondaryMenu.id);
                                                                        setMenuDataToEdit(null); // Reset edit data
                                                                    }}
                                                                >
                                                                    Add
                                                                </a>
                                                                <button className='btn btn-warning btn-sm me-3' onClick={() => {
                                                                    editHandler(secondaryMenu);
                                                                    setCurrentPrimaryId(primaryMenu.id);
                                                                    setCurrentSecondaryId(secondaryMenu.id);
                                                                }} data-bs-toggle='offcanvas' href='#secondaryMenuSidebar'>Edit</button>
                                                                <button className='btn btn-danger btn-sm' onClick={() => deleteHandler(secondaryMenu.id, primaryMenu.id)}>Delete</button>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    {secondaryMenu.tertiaryMenu.map((tertiaryMenu) => (
                                                        <ul className='ms-5'>
                                                            <li key={tertiaryMenu.id} className='list-group-item d-flex justify-content-between align-items-center'>
                                                                {tertiaryMenu.menuName}
                                                                <div>
                                                                    <button className='btn btn-warning btn-sm me-3' onClick={() => {
                                                                        editHandler(tertiaryMenu);
                                                                        setCurrentPrimaryId(primaryMenu.id);
                                                                        setCurrentSecondaryId(secondaryMenu.id);
                                                                    }} data-bs-toggle='offcanvas' href='#tertiaryMenuSidebar'>Edit</button>
                                                                    <button className='btn btn-danger btn-sm' onClick={() => deleteHandler(tertiaryMenu.id, primaryMenu.id, secondaryMenu.id)}>Delete</button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    ))}
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </ul>
                                <a className='d-block' data-bs-toggle='offcanvas' role='button'
                                    href='#primaryMenuSidebar' aria-controls='primaryMenuSidebar'>
                                    <li className='list-group-item d-flex editMenu bg-success text-light justify-content-center'>Add Primary Menu</li>
                                </a>
                                <Offcanvas
                                    setChanges={setChanges}
                                    id="primaryMenuSidebar"
                                    canvasLabel="Add/Edit Primary Menu"
                                    onAdd={addOrUpdatePrimaryMenu}
                                    menuDataToEdit={menuDataToEdit}
                                />
                                <Offcanvas
                                    setChanges={setChanges}
                                    id="secondaryMenuSidebar"
                                    canvasLabel={`Add/Edit Secondary Menu for ${mainMenu.menuList.find(menu => menu.id === currentPrimaryId)?.menuName}`}
                                    onAdd={addOrUpdateSecondaryMenu}
                                    menuDataToEdit={menuDataToEdit}
                                />
                                <Offcanvas
                                    setChanges={setChanges}
                                    id="tertiaryMenuSidebar"
                                    canvasLabel={`Add/Edit Tertiary Menu for ${mainMenu.menuList.find(menu => menu.id === currentPrimaryId)?.secondaryMenu.find(menu => menu.id === currentSecondaryId)?.menuName}`}
                                    onAdd={addOrUpdateTertiaryMenu}
                                    menuDataToEdit={menuDataToEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
