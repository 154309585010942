import {
  COLLECTION_LIST_ALL_REQUEST,
  COLLECTION_LIST_ALL_SUCCESS,
  COLLECTION_LIST_ALL_FAIL,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  COLLECTION_UPDATE_REQUEST,
  COLLECTION_UPDATE_SUCCESS,
  COLLECTION_UPDATE_FAIL,
  GET_COLLECTION_REQUEST,
  GET_COLLECTION_SUCCESS,
  GET_COLLECTION_FAIL,
  CREATE_COLLECTION_REQUEST,
  CREATE_COLLECTION_SUCCESS,
  CREATE_COLLECTION_FAIL

} from '../constants/collectionConstants'

export const collectionListAllReducer = (
  state = { allCollections: {  collections: [] } },
  action
) => {
  switch (action.type) {
    case COLLECTION_LIST_ALL_REQUEST:
      return { ...state, loading: true }
    case COLLECTION_LIST_ALL_SUCCESS:
      return { loading: false, collection: action.payload }
    case COLLECTION_LIST_ALL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const collectionListReducer = (
  state = { collection: {  collections: [] } },
  action
) => {
  switch (action.type) {
    case COLLECTION_LIST_REQUEST:
      return { ...state, loading: true }
    case COLLECTION_LIST_SUCCESS:
      return { loading: false, collection: action.payload }
    case COLLECTION_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const collectionUpdateReducer = (state = {collection :{}}, action) => {
  switch (action.type) {

    case COLLECTION_UPDATE_REQUEST:
      return {loading:true}
    case COLLECTION_UPDATE_SUCCESS:
      return {loading:false,success:true,product:action.payload}
    case COLLECTION_UPDATE_FAIL:
      return {loading:false,error:action.payload}
    default:
      return state
    
  }
}

export const getCollectionReducer = (
  state = { loading: false, error: null, fetchCollection: [] },
  action
) => {
  switch (action.type) {
    case GET_COLLECTION_REQUEST:
      return { ...state, loading: true }
    case GET_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchCollection: action.payload,
      }
    case GET_COLLECTION_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const collectionCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_COLLECTION_REQUEST:
      return { loading: true };
    case CREATE_COLLECTION_SUCCESS:
      return { loading: false, success: true, collection: action.payload };
    case CREATE_COLLECTION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};