import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import CategoryEditScreen from './screens/admin/category/CategoryEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import ProductCreateScreen from './screens/ProductCreateScreen'
import MenuScreen from './screens/admin/menu/MenuScreen'
import MenuListScreen from './screens/admin/menu/MenuListScreen'
import MobileMenuScreen from './screens/admin/menu/MobileMenuScreen'
import MobileMenu from './screens/admin/menu/MobileMenu'
import BrandScreen from './screens/admin/BrandScreen'
import CategoryScreen from './screens/category/CategoryScreen'
import categoryAdmin from './screens/admin/category/categoryAdmin'
import CategoryCreateScreen from './screens/admin/category/CategoryCreateScreen'
import HomepageScreen from './screens/admin/homepage/HomepageScreen'
import MenuCreateScreen from './screens/admin/menu/MenuCreateScreen'
import CollectionAdminScreen from './screens/admin/collections/CollectionAdminScreen'
import CollectionCreateScreen from './screens/admin/collections/CollectionCreateScreen'
import CollectionEditScreen from './screens/admin/collections/CollectionEditScreen'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './components/PrivateRoute/PrivateRoute'

const App = () => {
  return (
    <Router>
      <Header />

      <PrivateRoute path='/' component={HomeScreen} exact />

      <PrivateRoute path='/order/:id' component={OrderScreen} />
      <PrivateRoute path='/shipping' component={ShippingScreen} />
      <PrivateRoute path='/payment' component={PaymentScreen} />
      <PrivateRoute path='/placeorder' component={PlaceOrderScreen} />
      <Route path='/login' component={LoginScreen} />
      <PrivateRoute path='/register' component={RegisterScreen} />
      <PrivateRoute path='/profile' component={ProfileScreen} />
      <PrivateRoute path='/product/:id' component={ProductScreen} />
      <PrivateRoute path='/cart/:id?' component={CartScreen} />
      <PrivateRoute path='/admin/userlist' component={UserListScreen} />
      <PrivateRoute path='/admin/user/:id/edit' component={UserEditScreen} />
      <PrivateRoute path='/admin/productlist' component={ProductListScreen} exact />

      <PrivateRoute path='/category/:id' component={CategoryScreen} />
      <PrivateRoute path='/search' component={CategoryScreen} />

      <PrivateRoute path='/admin/productlist/:pageNumber' component={ProductListScreen} exact />
      <PrivateRoute path='/admin/product/:id/edit' component={ProductCreateScreen} />
      <PrivateRoute path='/admin/product/create' component={ProductCreateScreen} />

      {/* Menu admin screens  */}
      <PrivateRoute path='/admin/menu/create' component={MenuCreateScreen} />
      <PrivateRoute path='/admin/menus' component={MenuListScreen} />
      <PrivateRoute path='/admin/menu/edit/:id' component={MenuScreen} />

      {/* Mobile Menu admin screens  */}
      <PrivateRoute path='/admin/mobile-menu' component={MobileMenuScreen} exact />
      <PrivateRoute path='/admin/mobile-menu/edit/:id' component={MobileMenu} />

      {/* Brand admin screens  */}
      <PrivateRoute path='/admin/brands' component={BrandScreen} />

      {/* Homepage admin screens  */}
      <PrivateRoute path='/admin/homepage' component={HomepageScreen} />

      {/* Order admin screens  */}
      <PrivateRoute path='/admin/orderlist' component={OrderListScreen} />
      <PrivateRoute path='/page/:pageNumber' component={HomeScreen} exact />

      {/* Category Admin Screens  */}
      <PrivateRoute path='/admin/categoryList' component={categoryAdmin} />
      

      <PrivateRoute path='/admin/category/edit/:id' component={CategoryEditScreen} />
      <PrivateRoute path='/admin/category/create/' component={CategoryCreateScreen} />

      {/* Collection path  */}
      <PrivateRoute path='/admin/collections' component={CollectionAdminScreen} />
      <PrivateRoute path='/admin/collection/create' component={CollectionCreateScreen} />
      <PrivateRoute path='/admin/collection/:id/edit' component={CollectionEditScreen} />

      <ToastContainer />

      <Footer />
    </Router>
  )
}

export default App
