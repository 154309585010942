import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Table, Button, Row, Col } from 'react-bootstrap'
const apiUrl = process.env.REACT_APP_API_URL;
const MenuListScreen = () => {
  const [allMenu, SetAllmenu] = useState([])
  useEffect(() => {
    axios.get(`${apiUrl}/api/menu/all`).then((res) => {
      SetAllmenu(res.data)
    })
  }, [])
  return (
    <>
      <section>
        <div className='container'>
          <div className='row'>
            <Row className='align-items-center'>
              <Col>
                <h1>Menus</h1>
              </Col>
              <Col className='text-end'>
                <a href='/admin/menu/create'>
                  <Button className='my-3'>
                    <i className='fas fa-plus'></i> Create a Menu
                  </Button>
                </a>
              </Col>
            </Row>
            <div className='col-sm-12'>
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {allMenu.map((menu) => (
                    <tr key={menu._id}>
                      <td>{menu.name}</td>
                      <td>
                        <a href={`/admin/menu/edit/${menu._id}`}>
                          <Button variant='light' className='btn-sm'>
                            <i className='fas fa-edit'></i>
                          </Button>
                        </a>
                        <Button variant='danger' className='btn-sm'>
                          <i className='fas fa-trash'></i>
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default MenuListScreen
