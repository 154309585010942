import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import styles from './styles.module.css';
import Sidebar from '../../../components/Sidebar';
import AdminNav from '../../../components/admin/nav';
import { useDispatch,useSelector } from 'react-redux';
import { listHomepageScreens, updateHomepageScreensOrder } from '../../../actions/homeScreenAction'
import Alert from '../../../components/Alert/page';
import { showAlert } from '../../../utils/showAlert';

const HomepageScreen = () => {
  // const [showAlert, setShowAlert] = useState(false);
  const [homescreens, setHomescreens] = useState([]);
  const [searchedCategories, setSearchedCategories] = useState([]);
  const [newestOrder, setNewestOrder] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);

  const dispatch = useDispatch()
  const homepageScreenFetch = useSelector((state) => state.homepageScreenFetch)
  const { screens, loading, error } = homepageScreenFetch

  const searchCollections = (e) => {
    const { value } = e.target;
    fetchCollectionsByName(value);
  };

  const fetchCollectionsByName = async (name) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/category/name/${name}`);
      const data = await response.json();
      setSearchedCategories(data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCategoryItemClick = async (categoryId) => {
    const selectedCategory = searchedCategories.find(
      (category) => category._id === categoryId
    );
    console.log(selectedCategory,'selectedCategory');
  
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
  
      try {
        // Define the data to be sent in the POST request
        const postData = {
          count: 10, // Set the desired count value
          categoryId: selectedCategory._id, // Use the categoryId from the selectedCategory
        };
  
        // Send a POST request to the /api/homescreen/ endpoint with the data
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/homescreen/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(postData),
        });
  
        if (response.ok) {
          showAlert('Added Successfully!','success')
        } else {
          // Handle errors if the request was not successful
          console.error('Error creating homescreen:', response.statusText);
        }
      } catch (error) {
        console.error('Error creating homescreen:', error);
      }
    }
  };



  useEffect(() => {
    dispatch(listHomepageScreens())
  },[])


  const onDragEnd = async (result) => {
    setIsUpdated(true)
    if (!result.destination) return; // dropped outside the list
  
    const items = [...screens];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

  
    // setHomescreens(items);
  
    const newOrder = items.map((screen,i) => ({
      index: i,
      _id: screen._id,
      categoryId: screen.categoryId,
      count: screen.count,
      name: screen.name
    }));

    setHomescreens(items.map((screen,i) => ({
      index: i,
      _id: screen._id,
      categoryId: screen.categoryId,
      count: screen.count,
      type: screen.type
    })));

    setNewestOrder(newOrder)
    dispatch(updateHomepageScreensOrder(newOrder)); 

  };

  const updateHomepageOrder = async () => {

    try {
      // Send a PUT request to update the order on the backend
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/homescreen/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ homescreens }),
      });
  
      if (response.ok) {
        showAlert('Updated Order!','success')
      } else {
        // Handle errors if the request was not successful
        console.error('Error updating homescreen order:', response.statusText);
      }
    } catch (error) {
      console.error('Error updating homescreen order:', error);
    }
  }
  

  return (
    <>

      <AdminNav isView={false} update={updateHomepageOrder} isUpdated={isUpdated} />
      <div className="container">
        <ul className="px-0 pt-3">
          <a
            className="d-block"
            data-bs-toggle="offcanvas"
            href="#createCategorySidebar"
            role="button"
            aria-controls="createCategorySidebar"
          >
            <li className="list-group-item d-flex editMenu">Add a section to homepage</li>
          </a>
        </ul>

        <Sidebar
          searchCollections={searchCollections}
          searchedCategories={searchedCategories}
          handleCategoryItemClick={handleCategoryItemClick}
        />

{
  loading ? (
    <div>Loading...</div>
  ) : error ? (
    <div>Error - Please reload the page</div>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="homescreens">
        {(provided) => (
          <ul className="px-0 pt-3" {...provided.droppableProps} ref={provided.innerRef}>
            {/* Sort screens by index before mapping */}
            {screens
              .slice() // Create a copy of the array to avoid mutating the original
              .sort((a, b) => a.index - b.index) // Sort by index
              .map((screen, index) => (
                <Draggable key={screen._id} draggableId={screen._id} index={index}>
                  {(provided) => (
                    <li
                      className="list-group-item d-flex editMenu"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {screen.name}
                    </li>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  )
}

        {selectedCategory && (
          <ul className="px-0 pt-3">
            <li className="list-group-item d-flex editMenu">{selectedCategory.name}</li>
          </ul>
        )}
      </div>
    </>
  );
};

export default HomepageScreen;