import React, { useEffect, useState } from 'react'
import Login from '../components/auth/Login'
import Register from '../components/auth/Register'

const Modal = () => {
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)
  const [hideModal, setHideModal] = useState(false)

  function showRegisterScreen() {
    setShowRegister(true)
    setShowLogin(false)
  }

  function showLoginScreen() {
    setShowLogin(true)
    setShowRegister(false)
  }

  useEffect(() => {
    if (hideModal) {
      const button = document.getElementById('myButton')
      button.click()
    }
  }, [hideModal])

  return (
    <>
      <div
        className='modal fade'
        id='loginModal'
        data-bs-backdrop='static'
        data-bs-keyboard='false'
        tabIndex='-1'
        aria-labelledby='loginModalLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h1 className='modal-title fs-5 p-0' id='loginModalLabel'>
                {!showRegister && <>Login</>}
                {showRegister && <>Register</>}
              </h1>
              <button
                id='myButton'
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='container'>
                <div className='row'>
                  <div className='col-sm-12'>
                    {showRegister ? null : (
                      <Login setHideModal={setHideModal} />
                    )}
                    {showRegister ? (
                      <Register setShowLogin={setShowLogin} />
                    ) : null}

                    <p className='register-text'>
                      {!showRegister && (
                        <>
                          Don't have an account?
                          <a onClick={showRegisterScreen}> Register Here</a>
                        </>
                      )}

                      {showRegister && (
                        <>
                          Have an account?
                          <a onClick={showLoginScreen}> Login Here</a>
                        </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Modal
