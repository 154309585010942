import React, { useState, useEffect } from 'react'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;
const Brand = ({ setBrandData, brandData, setBrandName }) => {
  const [brand, setBrand] = useState()

  const handleBrandChange = (e) => {
    setBrandData(e.target.value)
    const selectedIndex = e.target.selectedIndex
    const selectedOption = e.target[selectedIndex]
    setBrandName(selectedOption.innerText)
  }

  async function getBrands() {
    try {
      let res = await axios({
        url: `${apiUrl}/api/brands/all`,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getBrands().then((res) => setBrand(res))
  }, [])

  return (
    <div className='mb-3'>
      <label htmlFor='pageTitle' className='form-label'>
        Brand
      </label>

      <select
        className='form-select'
        aria-label='select brand'
        onChange={(e) => handleBrandChange(e)}
        value={brandData}
      >
        <option value=''>
          Select Brand
        </option>
        {brand?.map((item, index) => (
          <option
            value={item._id}
            key={index}
            selected={item._id === brandData}
          >
            {item.name}
          </option>
        ))}
      </select>
    </div>
  )
}

export default Brand
