import {
    HOMEPAGE_SCREEN_FETCH_REQUEST,
    HOMEPAGE_SCREEN_FETCH_SUCCESS,
    HOMEPAGE_SCREEN_FETCH_FAIL,
    UPDATE_HOMEPAGE_SCREENS_ORDER,
    GET_PRODUCTS_REQUEST,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCTS_FAIL,
  } from "../constants/homepageScreenConstants";
  
  export const homepageScreenFetchReducer = (
    state = { loading: false, screens: [] },
    action
  ) => {
    switch (action.type) {
      case HOMEPAGE_SCREEN_FETCH_REQUEST:
        return { ...state, loading: true }; // Keep the existing state and set loading to true
  
      case HOMEPAGE_SCREEN_FETCH_SUCCESS:
        return { ...state, loading: false, screens: action.payload }; // Update screens data
  
      case HOMEPAGE_SCREEN_FETCH_FAIL:
        return { ...state, loading: false, error: action.payload }; // Update error if needed
  
      case UPDATE_HOMEPAGE_SCREENS_ORDER:
        return { ...state, screens: action.payload }; // Update screens array with newScreensOrder
  
      default:
        return state;
    }
  };
  

  export const getCategoriesReducer = (state = { categories: [] }, action) => {
    switch (action.type) {
      case GET_PRODUCTS_REQUEST:
        return { loading: true, categories: [] }
      case GET_PRODUCTS_SUCCESS:
        return {
          loading: false,
          categories: action.payload.categories,
        }
      case GET_PRODUCTS_FAIL:
        return { loading: false, error: action.payload }
      default:
        return state
    }
  }