export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'

export const CATEGORY_PRODUCTS_REQUEST = 'CATEGORY_PRODUCTS_REQUEST'
export const CATEGORY_PRODUCTS_SUCCESS = 'CATEGORY_PRODUCTS_SUCCESS'
export const CATEGORY_PRODUCTS_FAIL = 'CATEGORY_PRODUCTS_FAIL'

export const GET_CATEGORY_REQUEST = 'GET_CATEGORY_REQUEST'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL'

export const PUT_CATEGORY_REQUEST = 'PUT_CATEGORY_REQUEST'
export const PUT_CATEGORY_SUCCESS = 'PUT_CATEGORY_SUCCESS'
export const PUT_CATEGORY_FAIL = 'PUT_CATEGORY_FAIL'

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST'
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS'
export const CREATE_CATEGORY_FAIL = 'CREATE_CATEGORY_FAIL'

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL'

export const LIST_PRODUCTS_REQUEST = 'LIST_PRODUCTS_REQUEST'
export const LIST_PRODUCTS_SUCCESS = 'LIST_PRODUCTS_SUCCESS'
export const LIST_PRODUCTS_FAIL = 'LIST_PRODUCTS_FAIL'

export const LIST_PRODUCTS_BY_TAGS_REQUEST = 'LIST_PRODUCTS_BY_TAGS_REQUEST'
export const LIST_PRODUCTS_BY_TAGS_SUCCESS = 'LIST_PRODUCTS_BY_TAGS_SUCCESS'
export const LIST_PRODUCTS_BY_TAGS_FAIL = 'LIST_PRODUCTS_BY_TAGS_FAIL'
