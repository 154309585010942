export const HOMEPAGE_SCREEN_CREATE = 'HOMEPAGE_SCREEN_CREATE'

export const HOMEPAGE_SCREEN_FETCH_REQUEST = 'HOMEPAGE_SCREEN_FETCH_REQUEST'
export const HOMEPAGE_SCREEN_FETCH_SUCCESS = 'HOMEPAGE_SCREEN_FETCH_SUCCESS'
export const HOMEPAGE_SCREEN_FETCH_FAIL = 'HOMEPAGE_SCREEN_FETCH_FAIL'

export const HOMEPAGE_SCREEN_UPDATE = 'HOMEPAGE_SCREEN_UPDATE'
export const HOMEPAGE_SCREEN_DELETE = 'HOMEPAGE_SCREEN_DELETE'

export const UPDATE_HOMEPAGE_SCREENS_ORDER = 'UPDATE_HOMEPAGE_SCREENS_ORDER'

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL'



