import React, { useState, useEffect } from 'react'
import { Toast } from "../services/Toast";
import Seo from '../components/Seo'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import Nav from '../components/products/Nav'
import Detail from '../components/products/Detail'
import NewSpec from '../components/products/NewSpec'
import NewFeatures from '../components/products/NewFeatures'
import { listAllCollections } from '../actions/collectionAction'
import Variants from '../components/products/create/Variants'
import { showAlert } from '../utils/showAlert'
const apiUrl = process.env.REACT_APP_API_URL;

export default function ProductCreateScreen({ match, history }) {

  const [dataChanged, setDataChanged] = useState(false);
  
  function dataChangeHandler(value) {
    if (value === '') {
      setDataChanged(false);
    } else {
      setDataChanged(true);
    }
  }
  const pId = match.params.id
  const [path, setPath] = useState('');
  const editPath = '/admin/product/:id/edit';

  const dispatch = useDispatch();
  const allCollections = useSelector((state) => state.allCollections);

  // loading
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //for create product
  const [name, setName] = useState('');
  const [productId, setProductId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [sku, setSKU] = useState('');
  const [category, setCategory] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [slug, setSlug] = useState('');
  const [media, setMedia] = useState([]);
  const [price, setPrice] = useState('');
  const [wasPrice, setWasPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [brandData, setBrandData] = useState('646cd8a00d1461aabc915564');
  const [brandName, setBrandName] = useState('');
  const [description, setDescription] = useState('');
  const [isActive, setIsActive] = useState(true);
  const [features, setFeatures] = useState([]);
  const [x, setX] = useState('');
  const [optionsData, setOptionsData] = useState([]);
  const [lastUpdated,setLastUpdated] = useState('')

  const [optionsDataNew, setOptionsDataNew] = useState([]);

  const categoryset = (event) => {
    const selectedOption = event.target.options[event.target.selectedIndex]
    const hiddenValue = selectedOption.dataset.hiddenValue
    setCategory(event.target.value);
    setCategoryId(hiddenValue);
  }

  // seo
  const [seoData, setSeoData] = useState([])
  const [specifications, setSpecifications] = useState([])
  const [seo, setSeo] = useState({ pageTitle: '', metaDescription: '' })

  const [tax, setTax] = useState({taxTitle: '',taxState: '',taxRate: ''})
  
  const [pageTitle, setPageTitle] = useState(seo.pageTitle)
  const [metaDescription, setMetaDescription] = useState(seo.metaDescription)

  const [categoryNew, setCategoryNew] = useState('')
  const [selectedCategory, setSelectedCategory] = useState(
    '646c6a0485ed7c0dcc5fe68e'
  )

  const [tags, setTags] = useState([])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setSeo({ ...seo, [name]: value })
  }
  const handleTaxChange = (event) => {
    const { name, value } = event.target;
    setTax((prevTax) => ({
      ...prevTax,
      [name]: value
    }));
  };


  //login info
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  // features
  const [allFeatures, setAllFeatures] = useState([])

  const [highlights, setHighlights] = useState([])

  // store data
  const data = {
    _id: pId,
    name: name,
    group_id: groupId,
    category: category,
    categoryId: selectedCategory,
    slug: slug,
    sku: sku,
    image: media,
    price: price,
    quantity: quantity,
    wasPrice: wasPrice,
    description: description,
    isActive: isActive,
    brandId: brandData,
    features: allFeatures,
    specifications: specifications,
    seo: seo,
    tags: tags,
    brand: brandName,
    highlights: highlights,
    tax: tax
  }
  // image upload
  const [file, setFile] = useState([])
  const [uploadedFiles, setUploadedFiles] = useState([])

  // upload media
  const [isUploadTriggered, setIsUploadTriggered] = useState(false);

  const uploadFileHandler = (e) => {
    e.preventDefault()
    const showMedia = document.getElementById('showMedia')
    const filenameList = Array.prototype.map.call(
      e.target.files,
      function (file) {
        return file.name
      }
    )
    showMedia.innerHTML += filenameList
    const formData = new FormData()
    formData.append('slug', slug);
    formData.append('productObjectId', productId);
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append('image', e.target.files[i])
    }

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      axios({
        method: 'post',
        config: config,
        data: formData,
        url: `${apiUrl}/api/upload`,
      }).then((res) => {
        let arr = res.data.split(',')
        media.push(...arr)
        updateProductData(true)
      })
    } catch (error) {}
  }


  // updates the product
  function updateProductData(isTrue) {
    setLoading(true);
    axios({
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      data: data,
      url: `${apiUrl}/api/products/edit/${pId}`,
    }).then((res) => {
      setLoading(false);
      Toast({ type: 'success', message: 'Product updated successfully!' });
      if (isTrue) {
        setIsUploadTriggered(true);
      }
    }).catch((error) => {
      if (error.response) {
        showAlert(error.response.data.message,'danger');
      }
      else if (error.request) {
        showAlert('Error','danger');
      } else {
        console.error("Error:", error.message);
      }
    });
  }

  useEffect(() => {
    if (isUploadTriggered) {
      window.location.reload();
    }
  }, [isUploadTriggered]);

  

  // edit and create
  function submitHandler(e) {
    e.preventDefault();
  
    if (path === editPath) {
      updateProductData();
    } else {
      axios({
        method: 'post',
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
        data: data,
        url: `${apiUrl}/api/products`,
      })
      .then((res) => {
        // res
        const productId = res.data._id;
        history.push(`/admin/product/${productId}/edit`);
        Toast({ type: 'success', message: 'Product saved successfully!' });
      })
      .catch((error) => {
        if (error.response) {
          showAlert(error.response.data.message,'danger');
        }
        else if (error.request) {
          // The request was made but no response was received
          console.error("No response received from server:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      });
    }
  }
  

  // gets product data
  async function getData() {
    try {
      let res = await axios({
        url: `${apiUrl}/api/products/edit/${pId}`,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      if (res.status == 200) {
        setName(res.data.name)
        setGroupId(res.data.group_id)
        setCategory(res.data.category)
        setCategoryId(res.data.categoryId)
        setSelectedCategory(res.data.categoryId)
        setSlug(res.data.slug)
        setSKU(res.data.sku)
        setMedia(res.data.image)
        setPrice(res.data.price)
        setWasPrice(res.data.wasPrice)
        setQuantity(res.data.quantity)
        setDescription(res.data.description)
        setIsActive(res.data.isActive)
        setBrandData(res.data.brandId)
        setSpecifications(res.data.specifications)
        setFeatures(res.data.features)
        setTags(res.data.tags)
        setOptionsData(res.data.optionsData)
        setCategoryNew(res.data.categoryId)
        setBrandName(res.data.brand)
        setProductId(res.data._id)
        setHighlights(res.data.highlights)
        setLastUpdated(res.data.updatedAt)

        setSeo({
          pageTitle: res.data.seo[0].pageTitle,
          metaDescription: res.data.seo[0].metaDescription,
        })

        setPageTitle(res.data.seo[0].pageTitle)
        setMetaDescription(res.data.seo[0].metaDescription)
        setX(res.data.name)
        setTax(res.data.tax)
      }
      return res.data
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const header = document.getElementById('header')
    header.classList.remove('sticky-top')
    setPath(match.path)
    getData()
    dispatch(listAllCollections())
  }, [setSeoData, setSpecifications])

  const options = { 
    day: 'numeric', 
    month: 'long', 
    year: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit', 
    hour12: true, 
    timeZone: 'UTC' 
  };
  
  const formattedDate = new Date(lastUpdated).toLocaleString('en-GB', options);

  return (
    <>
      <style>{` .secondary-menu{display:none}`}</style>

      <form id='form'>
        <Nav
          slug={slug}
          submitHandler={submitHandler}
          loading={loading}
          error={error}
          errorMessage={errorMessage}
          dataChanged={dataChanged}
        />

        {
          path === '/admin/product/create' ? <></> :
          <div className='container'>
            <div className='row'>
              <p className='updated'>Updated on {formattedDate}</p>
            </div>
          </div>
        }

        

        <section>
          <div className='container'>
            <div className='accordion' id='accordionPanelsStayOpenExample'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingOne'>
                  <button
                    className='accordion-button collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseOne'
                    aria-expanded='true'
                    aria-controls='panelsStayOpen-collapseOne'
                  >
                    Details 
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseOne'
                  className='accordion-collapse collapse show'
                  aria-labelledby='panelsStayOpen-headingOne'
                >
                  <div className='accordion-body'>
                    <Detail
                      productId={productId}
                      setBrandName={setBrandName}
                      dataChangeHandler={dataChangeHandler}
                      groupId={groupId}
                      setGroupId={setGroupId}
                      pId={pId}
                      optionsDataNew={optionsDataNew}
                      setOptionsDataNew={setOptionsDataNew}
                      setSelectedCategory={setSelectedCategory}
                      selectedCategory={selectedCategory}
                      categoryNew={categoryNew}
                      setSKU={setSKU}
                      sku={sku}
                      submitHandler={submitHandler}
                      setOptionsData={setOptionsData}
                      optionsData={optionsData}
                      categoryset={categoryset}
                      categoryId={categoryId}
                      setCategoryId={setCategoryId}
                      allCollections={allCollections}
                      tags={tags}
                      setTags={setTags}
                      pageTitle={pageTitle}
                      seoData={seoData}
                      metaDescription={metaDescription}
                      handleInputChange={handleInputChange}
                      handleTaxChange={handleTaxChange}
                      axios={axios}
                      setSlug={setSlug}
                      setCategory={setCategory}
                      category={category}
                      setName={setName}
                      setPrice={setPrice}
                      setWasPrice={setWasPrice}
                      setQuantity={setQuantity}
                      setDescription={setDescription}
                      setIsActive={setIsActive}
                      isActive={isActive}
                      setBrandData={setBrandData}
                      brandData={brandData}
                      setFile={setFile}
                      setMedia={setMedia}
                      file={file}
                      uploadFileHandler={uploadFileHandler}
                      uploadedFiles={uploadedFiles}
                      Seo={Seo}
                      name={name}
                      slug={slug}
                      description={description}
                      price={price}
                      wasPrice={wasPrice}
                      quantity={quantity}
                      media={media}
                      setSeo={setSeo}
                      seo={seo}
                      setHighlights={setHighlights}
                      highlights={highlights}
                      tax={tax}
                    />
                  </div>
                </div>
              </div>

              <div className='accordion-item'>
                <h2 className='accordion-header' id='panelsStayOpen-headingTwo'>
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseTwo'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseTwo'
                  >
                    Features
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseTwo'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingTwo'
                >
                  <div className='accordion-body'>
                    <NewFeatures
                      name={name}
                      setAllFeatures={setAllFeatures}
                      theFeatures={features}
                    />
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2
                  className='accordion-header'
                  id='panelsStayOpen-headingThree'
                >
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseThree'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseThree'
                  >
                    Specifications
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseThree'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingThree'
                >
                  <div className='accordion-body'>
                    <NewSpec
                      setSpecifications={setSpecifications}
                      specifications={specifications}
                    />
                  </div>
                </div>
              </div>

              <div className='accordion-item'>
                <h2
                  className='accordion-header'
                  id='panelsStayOpen-headingFour'
                >
                  <button
                    className='accordion-button'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#panelsStayOpen-collapseFour'
                    aria-expanded='false'
                    aria-controls='panelsStayOpen-collapseFour'
                  >
                    Variants
                  </button>
                </h2>
                <div
                  id='panelsStayOpen-collapseFour'
                  className='accordion-collapse collapse'
                  aria-labelledby='panelsStayOpen-headingFour'
                >
                  <div className='accordion-body'>
                    <Variants
                     slug={slug}
                     pId={pId}
                     optionsDataNew={optionsDataNew}
                     setOptionsDataNew={setOptionsDataNew}
                     setGroupId={setGroupId}
                     groupId={groupId}
                     showAlert={showAlert}
                     />
                    {/* <Options name={name} slug={slug} groupId={groupId} /> */}
                  </div>
                </div>
              </div>

            
            </div>
          </div>
        </section>
      </form>
    </>
  )
}
