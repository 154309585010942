import axios from 'axios'
import { HOMEPAGE_SCREEN_FETCH_SUCCESS, HOMEPAGE_SCREEN_FETCH_REQUEST,UPDATE_HOMEPAGE_SCREENS_ORDER, GET_PRODUCTS_REQUEST, GET_PRODUCTS_SUCCESS, GET_PRODUCTS_FAIL } from "../constants/homepageScreenConstants";
const apiUrl = process.env.REACT_APP_API_URL;
export const listHomepageScreens = () => async (dispatch) => {
    try{
        dispatch({ type: HOMEPAGE_SCREEN_FETCH_REQUEST })
        const { data } = await axios.get(`${apiUrl}/api/homescreen/all/`)
        dispatch({
            type: HOMEPAGE_SCREEN_FETCH_SUCCESS,
            payload: data
        })
    }catch{
        
    }
}

export const updateHomepageScreensOrder = (newScreensOrder) => (dispatch) => {
    try {
        dispatch({
            type: UPDATE_HOMEPAGE_SCREENS_ORDER,
            payload: newScreensOrder,
        });

    } catch (error) {
        // alert(error)
      // Handle errors here if needed
    }
};

export const getProducts = () => async (dispatch) => {

    try {
      dispatch({ type: GET_PRODUCTS_REQUEST })

      const { data } = await axios.get(
        `${apiUrl}/api/homescreen/products`
      )

      dispatch({
        type: GET_PRODUCTS_SUCCESS,
        payload: data,
      })
    } catch (error) {
      dispatch({
        type: GET_PRODUCTS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }

}