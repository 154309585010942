import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_PRODUCTS_REQUEST,
  CATEGORY_PRODUCTS_SUCCESS,
  CATEGORY_PRODUCTS_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  LIST_PRODUCTS_REQUEST,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAIL,
  LIST_PRODUCTS_BY_TAGS_REQUEST,
  LIST_PRODUCTS_BY_TAGS_SUCCESS,
  LIST_PRODUCTS_BY_TAGS_FAIL,
} from '../constants/categoryConstants'

export const categoryListReducer = (
  state = { loading: false, error: null, categories: [] },
  action
) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { ...state, loading: true }
    case CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      }
    case CATEGORY_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryDetailReducer = (
  state = { loading: false, error: null, category: null },
  action
) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return { ...state, loading: true }
    case CATEGORY_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      }
    case CATEGORY_DETAILS_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryProductsReducer = (
  state = { loading: false, error: null, products: [] },
  action
) => {
  switch (action.type) {
    case CATEGORY_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case CATEGORY_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload,
      }
    case CATEGORY_PRODUCTS_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const getCategoryReducer = (
  state = { loading: false, error: null, fetchCategory: [] },
  action
) => {
  switch (action.type) {
    case GET_CATEGORY_REQUEST:
      return { ...state, loading: true }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        fetchCategory: action.payload,
      }
    case GET_CATEGORY_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const putCategoryReducer = (
  state = { loading: false, error: null, categories: [] },
  action
) => {
  switch (action.type) {
    case GET_CATEGORY_REQUEST:
      return { ...state, loading: true }
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      }
    case GET_CATEGORY_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const createCategoryReducer = (
  state = { loading: false, error: null, category: [] },
  action
) => {
  switch (action.type) {
    case CREATE_CATEGORY_REQUEST:
      return { ...state, loading: true }
    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        category: [action.payload],
      }
    case CREATE_CATEGORY_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteACategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CATEGORY_REQUEST:
      return { loading: true }
    case DELETE_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case DELETE_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const listProductsInCategoryReducer = (
  state = { loading: false, success: false, products: [] },
  action
) => {
  switch (action.type) {
    case LIST_PRODUCTS_REQUEST:
      return { ...state, loading: true }
    case LIST_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        products: action.payload,
      }
    case LIST_PRODUCTS_FAIL:
      return { ...state, loading: false, success: false, error: action.payload }
    default:
      return state
  }
}

export const productsInCategoryByTagsReducer = (
  state = { loading: false, success: false, productsByTags: [] },
  action
) => {
  switch (action.type) {
    case LIST_PRODUCTS_BY_TAGS_REQUEST:
      return { ...state, loading: true }
    case LIST_PRODUCTS_BY_TAGS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        productsByTags: action.payload,
      }
    case LIST_PRODUCTS_BY_TAGS_FAIL:
      return { ...state, loading: false, success: false, error: action.payload }
    default:
      return state
  }
}
