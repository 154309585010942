import axios from 'axios'
import {
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  CATEGORY_PRODUCTS_REQUEST,
  CATEGORY_PRODUCTS_SUCCESS,
  CATEGORY_PRODUCTS_FAIL,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORY_FAIL,
  PUT_CATEGORY_REQUEST,
  PUT_CATEGORY_SUCCESS,
  PUT_CATEGORY_FAIL,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAIL,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAIL,
  LIST_PRODUCTS_REQUEST,
  LIST_PRODUCTS_SUCCESS,
  LIST_PRODUCTS_FAIL,
  LIST_PRODUCTS_BY_TAGS_REQUEST,
  LIST_PRODUCTS_BY_TAGS_SUCCESS,
  LIST_PRODUCTS_BY_TAGS_FAIL,
} from '../constants/categoryConstants'
const apiUrl = process.env.REACT_APP_API_URL;

export const listCategories =
  (keyword = '', pageNumber = '') =>
  async (dispatch) => {
    try {
      dispatch({ type: CATEGORY_LIST_REQUEST })

      const { data } = await axios.get(`${apiUrl}/api/category`)

      dispatch({
        type: CATEGORY_LIST_SUCCESS,
        payload: data.categories,
      })
    } catch (error) {
      dispatch({
        type: CATEGORY_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const listCategoryDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_DETAILS_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/category/${id}`)

    dispatch({
      type: CATEGORY_DETAILS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CATEGORY_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const listCategoryProducts = (id, queryParams) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_PRODUCTS_REQUEST })

    const config = {
      params: queryParams,
    }

    const { data } = await axios.get(`${apiUrl}/api/category/products/filter/${id}`, config)
    const { products, brands, variants, colors } = data

    dispatch({
      type: CATEGORY_PRODUCTS_SUCCESS,
      payload: { products, brands, variants, colors },
    })
  } catch (error) {
    dispatch({
      type: CATEGORY_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// for editing a category
export const getCategoryById = (id) => async (dispatch) => {
  try {
    dispatch({ type: GET_CATEGORY_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/category/edit/${id}`)

    dispatch({
      type: GET_CATEGORY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// for updating a category
export const putCategoryById = (id, category) => async (dispatch) => {
  try {
    dispatch({ type: PUT_CATEGORY_REQUEST })

    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    }

    const { data } = await axios.put(
      `${apiUrl}/api/category/edit/${id}`,
      category,
      config
    )

    dispatch({
      type: PUT_CATEGORY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: PUT_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// for creating a category
export const createCategory = (category) => async (dispatch,getState) => {
  try {
    dispatch({ type: CREATE_CATEGORY_REQUEST })

    const {
      userLogin: { userInfo },
    } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userInfo.token}`,
      },
    }

    const { data } = await axios.post(`${apiUrl}/api/category`, category, config)

    dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: CREATE_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// for deleting a category
export const deleteCategory = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CATEGORY_REQUEST })

    const { data } = await axios.delete(`${apiUrl}/api/category/edit/${id}`)

    dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// listing all products in a category
export const listProductsInCategoryById = (slug) => async (dispatch) => {
  try {
    dispatch({ type: LIST_PRODUCTS_REQUEST })

    if (slug) {
      const { data } = await axios.get(`${apiUrl}/api/category/products/${slug}`)
    

    dispatch({
      type: LIST_PRODUCTS_SUCCESS,
      payload: data,
    })
  }
  } catch (error) {
    dispatch({
      type: LIST_PRODUCTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// listing all products in a category by tags
export const listProductsInCategoryByTags = (slug) => async (dispatch) => {
  try {
    dispatch({ type: LIST_PRODUCTS_BY_TAGS_REQUEST })

    const { data } = await axios.get(`${apiUrl}/api/category/products/tags/${slug}`)

    dispatch({
      type: LIST_PRODUCTS_BY_TAGS_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: LIST_PRODUCTS_BY_TAGS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
