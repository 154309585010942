import React, { useState } from 'react'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;
const MenuCreateScreen = () => {
  const [menuName, setMenuName] = useState('')
  const [menuSlug, setMenuSlug] = useState('')

  const createNewMenuHandler = (e) => {
    if (e.target.name === 'name') {
      setMenuName(e.target.value)
    } else if (e.target.name === 'slug') {
      setMenuSlug(e.target.value)
    }
  }

  const submitHandler = async () => {
    try {
      const response = await axios.post(`${apiUrl}/api/menu`, {
        name: menuName,
        slug: menuSlug,
      })
      window.location.href = '/admin/menus'
    } catch (error) {
      // Handle error here
      console.error(error)
    }
  }

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12'>
          <h3>Create a menu</h3>
          <div className='card mb-3'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label htmlFor='menuName' className='form-label'>
                      Menu Name
                    </label>
                    <input
                      type='text'
                      autoComplete='off'
                      name='name'
                      className='form-control'
                      id='menuName'
                      placeholder=''
                      onChange={createNewMenuHandler}
                    />
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='mb-3'>
                    <label htmlFor='menuSlug' className='form-label'>
                      Slug
                    </label>
                    <input
                      type='text'
                      name='slug'
                      className='form-control'
                      id='menuSlug'
                      onChange={createNewMenuHandler}
                    />
                  </div>
                </div>
                <div className='mb-3'>
                  <button className='btn btn-success' onClick={submitHandler}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MenuCreateScreen
