import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
const apiUrl = process.env.REACT_APP_API_URL;
const BrandScreen = ({ match, history }) => {
  //login info
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [path, setPath] = useState('')
  const editPath = '/admin/collection/:id/edit'
  // data
  const [brand, setBrand] = useState([])
  const [brands, newBrands] = useState({
    name: '',
    slug: '',
    description: '',
  })
  // form save
  function submitHandler() {
    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      data: brands,
      url: `${apiUrl}/api/brands/create`,
    }).then((res) => {})
  }

  const createNewBrandHandler = (e) => {
    newBrands({ ...brands, [e.target.name]: e.target.value })
  }

  const deleteBrand = (id) => {
    axios({
      method: 'delete',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      url: `${apiUrl}/api/brands/delete/${id}`,
    }).then((res) => {})
  }

  const getAllBrands = () => {
    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
      url: `${apiUrl}/api/brands/all`,
    }).then((res) => {
      setBrand(res.data)
    })
  }

  useEffect(() => {
    getAllBrands()
    setPath(match.path)
  }, [deleteBrand])

  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h3>Create a brand</h3>
            <div className='card mb-3'>
              <div className='card-body'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='mb-3'>
                      <label htmlFor='productName' className='form-label'>
                        Brand Name
                      </label>
                      <input
                        type='text'
                        autoComplete='off'
                        name='name'
                        className='form-control'
                        id='productName'
                        placeholder=''
                        onChange={(e) => createNewBrandHandler(e)}
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='mb-3'>
                      <label htmlFor='productSlug' className='form-label'>
                        Slug
                      </label>
                      <input
                        type='text'
                        name='slug'
                        className='form-control'
                        id='productSlug'
                        onChange={(e) => createNewBrandHandler(e)}
                      />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label htmlFor='productDescription' className='form-label'>
                      Description
                    </label>
                    <textarea
                      className='form-control'
                      name='description'
                      id='productDescription'
                      rows='3'
                      onChange={(e) => createNewBrandHandler(e)}
                    ></textarea>
                  </div>
                  <div className='mb-3'>
                    <button className='btn btn-success' onClick={submitHandler}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              {brand?.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className='card'>
                      <span
                        className='imagePosition delete'
                        onClick={() => deleteBrand(item._id)}
                      >
                        Delete
                      </span>
                      <div className='card-body'>{item.name}</div>
                      <div className='card-body'>{item.slug}</div>
                      <div className='card-body'>{item.description}</div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default BrandScreen
