import React from 'react'

const Seo = ({ handleInputChange, seo }) => {
  return (
    <>
      <div className='card mt-3 mb-5'>
        <div className='card-header'>
          Search engine listing
          <p className='mb-0 mt-2'>
            Add a title and description to see how this product might appear in
            a search engine listing
          </p>
        </div>
        <div className='card-body'>
          <div className='mb-3'>
            <label htmlFor='pageTitle' className='form-label'>
              Page title
            </label>
            <input
              type='text'
              className='form-control'
              id='pageTitle'
              placeholder=''
              name='pageTitle'
              defaultValue={seo.pageTitle}
              onChange={handleInputChange}
            />
          </div>

          <div className='mb-3'>
            <label htmlFor='metaDescription' className='form-label'>
              Meta description
            </label>
            <textarea
              className='form-control'
              id='metaDescription'
              name='metaDescription'
              rows='3'
              defaultValue={seo.metaDescription}
              onChange={handleInputChange}
            ></textarea>
          </div>
        </div>
      </div>
    </>
  )
}

export default Seo
