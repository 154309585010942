import React, { useState, useEffect } from 'react';
import InputField from './InputField';
import axios from 'axios'; // Ensure you have axios installed

const Offcanvas = ({ id, canvasLabel, onAdd, setChanges, menuDataToEdit }) => {
    const [menuData, setMenuData] = useState({
        menuName: '',
        menuURL: '',
        id: '',
        secondaryMenu: [],
        featuredImage: ''
    });

    const [categories, setCategories] = useState([]);
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (menuDataToEdit) {
            setMenuData(menuDataToEdit);
        } else {
            setMenuData({ menuName: '', menuURL: '', id: '', secondaryMenu: [] }); // Reset form when there's no data to edit
        }
    }, [menuDataToEdit]);

    useEffect(() => {
        if (menuData.menuURL && !menuData.menuURL.startsWith('/categories/') && !menuData.menuURL.startsWith('/products/')) {
            fetchSearchResults(menuData.menuURL);
        } else {
            setCategories([]);
            setProducts([]);
        }
    }, [menuData.menuURL]);

    // old code before image upload 
    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setMenuData(prevState => ({
    //         ...prevState,
    //         [name]: value
    //     }));
    // };

    const handleChange = (e) => {
        const { name, value, files } = e.target;
      
        if (name === 'featuredImage') {
          setMenuData((prevState) => ({
            ...prevState,
            featuredImage: files[0], // Store the selected file in the state
          }));
        } else {
          setMenuData((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
      };
      

    const handleAddMenu = () => {
        setChanges(true);
        onAdd(menuData);
        setMenuData({ menuName: '', menuURL: '', id: '', secondaryMenu: [] }); // Reset form
    };

    const fetchSearchResults = async (keyword) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search?keyword=${keyword}`);
            setCategories(response.data.categories);
            setProducts(response.data.products);
            setError(null); // Clear any previous errors
        } catch (error) {
            console.error('Error fetching search results:', error);
            setError('Error fetching search results. Please try again.'); // Display an error message
        }
    };

    const handleResultClick = (slug, type) => {
        const url = type === 'category' ? `/categories/${slug}` : `/products/${slug}`;
        setMenuData(prevState => ({
            ...prevState,
            menuURL: url
        }));
    };

    return (
        <div className='offcanvas offcanvas-start' tabIndex='-1' id={id} aria-labelledby={`${id}Label`}>
            <div className='offcanvas-header'>
                <h5 className='offcanvas-title'>{canvasLabel}</h5>
                <button type='button' className='btn-close' data-bs-dismiss='offcanvas' aria-label='Close' />
            </div>
            <div className='offcanvas-body'>
                {error && <p className="text-danger">{error}</p>}
                <InputField
                    type='text'
                    id='menuName'
                    name='menuName'
                    label='Menu Name'
                    placeholder='Enter menu name'
                    value={menuData.menuName}
                    onChange={handleChange}
                />
                <InputField
                    type='text'
                    id='menuURL'
                    name='menuURL'
                    label='Menu URL'
                    placeholder='Enter menu URL'
                    value={menuData.menuURL}
                    onChange={handleChange}
                />

                <InputField
                    type="file"
                    id="menuImg"
                    name="featuredImage" // The same as the field expected by Multer
                    label="Menu Featured Image"
                    onChange={handleChange}
                />

                {
                    menuData.featuredImage ? 
                    <div className='featuredd'>
                        <img src={ process.env.REACT_APP_IMAGE_URL + "/uploads/icons/" + menuData.featuredImage} alt="" />
                    </div>
                :
                null
                }

                
                

                <button className='btn btn-success' type='button' onClick={handleAddMenu} data-bs-dismiss='offcanvas'>
                    Done
                </button>

                {menuData.menuURL && (
                    <div className='mt-3'>

                    <div>
                        <h6>Category</h6>
                        {categories.length > 0 ? (
                                    <ol>
                                        {categories.map((category) => (
                                            <li key={category._id} onClick={() => handleResultClick(category.slug, 'category')} style={{ cursor: 'pointer' }}>
                                                {category.name}
                                            </li>
                                        ))}
                                    </ol>
                                ) : (
                                    <p>No categories found.</p>
                                )}
                    </div>

                    <div>
                        <h6>Products</h6>
                         {products.length > 0 ? (
                                    <ol>
                                        {products.map((product) => (
                                            <li key={product._id} onClick={() => handleResultClick(product.slug, 'product')} style={{ cursor: 'pointer' }}>
                                                {product.name}
                                            </li>
                                        ))}
                                    </ol>
                                ) : (
                                    <p>No products found.</p>
                                )}
                    </div>
                    </div>

                )}
            </div>
        </div>
    );
};

export default Offcanvas;
