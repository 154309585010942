import React, { useState } from 'react'
import * as XLSX from 'xlsx';

function NewSpec({ setSpecifications, specifications }) {
  const [spec, setSpec] = useState([])

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)

      fileReader.onload = (e) => {
        const bufferArray = e.target.result
        const wb = XLSX.read(bufferArray, { type: 'buffer' })
        const wsname = wb.SheetNames[0]
        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        const specs = []

        for (let item of data) {
          let heading = item.Heading.trim()
          let title = item.Title.trim()
          let description = String(item.Description).trim()
          // Find the spec object for the current heading, or create a new one
          let spec = specs.find((s) => s.heading === heading)
          if (!spec) {
            spec = { heading, specification: [] }
            specs.push(spec)
          }
          // Add the current item as a specification object under the current heading
          spec.specification.push({ title, description })
        }
        resolve(specs)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })

    promise.then((d) => {
      const specs = Object.values({ ...spec, ...d })
      setSpec(specs)
      setSpecifications(specs)
    })
  }

  return (
    <>
      {specifications && specifications.length ? (
        ''
      ) : (
        <div className='mb-3'>
          <p>No Specifications added. Upload xlsx file.</p>
        </div>
      )}

      <input
        type='file'
        className='mb-3'
        onChange={(e) => {
          const file = e.target.files[0]
          readExcel(file)
        }}
      />

      {specifications?.map((item) => {
        return (
          <div>
            <h4>{item.heading}</h4>
            <table className='specs'>
              <tbody>
                {item.specification?.map((spec) => {
                  return (
                    <tr>
                      <td scope='row'>{spec.title}</td>
                      <td scope='row'>{spec.description}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })}
    </>
  )
}

export default NewSpec
