
const showAlert = (message,type, duration = 3000) => {
  return new Promise((resolve) => {
    const alertId = Math.random().toString(36).substring(2);
    const alertElement = document.createElement('div');
    alertElement.className = `alert alert-${type} aleert`;
    alertElement.textContent = message;
    alertElement.setAttribute('role', 'alert');
    alertElement.id = alertId;
    document.body.appendChild(alertElement);

    setTimeout(() => {
      const element = document.getElementById(alertId);
      if (element) {
        element.remove();
      }
      resolve();
    }, duration);
  });
};

export {showAlert}