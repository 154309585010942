import React, { useState, useEffect } from 'react'
import {showAlert} from '../../../utils/showAlert'
const apiUrl = process.env.REACT_APP_API_URL;
const Category = ({productId}) => {

  const [searchedCategories,setsearchedCategories] = useState([])
  const [categories, setCategories] = useState('')
  // const [updated, setUpdated] = useState('')
  const [newProductAdded,setNewProductAdded] = useState(false)

  function searchHandler(e){
    const { value } = e.target
    fetchProductsByName(value)
  }

  const fetchProductsByName = async (name) => {
    try {
      const response = await fetch(`${apiUrl}/api/category/name/${name}`)
      const data = await response.json()
      setsearchedCategories(data)
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }

  async function addCategories(categoryId) {
    try {
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          productId: productId,
          categoryId: categoryId,
        }),
      });
  
      if (!response.ok) {
        // Handle the error response
        const errorData = await response.json(); // Extract error message from JSON response
        throw new Error(errorData.message || 'Failed to add product to category');
      }
      else{
        setNewProductAdded(true)
      }
  
    } catch (error) {
      alert(error.message);
    }
  }
  async function deleteHandler (categoryId) {
    try {
      const response = await fetch(`${apiUrl}/api/category/products/store/`, {
        method: 'DELETe',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
        body: JSON.stringify({
          productId: productId,
          categoryId: categoryId,
        }),
      });
  
      if (!response.ok) {
        // Handle the error response
        const errorData = await response.json(); // Extract error message from JSON response
        throw new Error(errorData.message || 'Failed to add product to category');
      }
      else{
        setNewProductAdded(true)
        showAlert('Removed Category','danger')
      }
  
    } catch (error) {
      alert(error.message);
    }
  }

  async function fetchCategories(productId) {
    try {
      const response = await fetch(`${apiUrl}/api/products/categories/find/${productId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json', // Set the content type to JSON
        },
      });
  
      if (!response.ok) {
        // Handle the error response
        // const errorData = await response.json();
        // throw new Error(errorData.message || 'Failed to add product to category');
      }
      else{
        const responseData = await response.json();
        setCategories(responseData)
        
      }
  
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    console.log('sssss');
    
    if (productId != null) {
      console.log(productId,'ss');
      fetchCategories(productId)
    }
  },[productId,newProductAdded])

  return(
    <>

<div className='mb-3'>
    <label htmlFor='tags' className='form-label'>
      Add Categories
    </label>
                    <input
                      onChange={(e) => searchHandler(e)}
                      type='search'
                      autoComplete='off'
                      name='searchHandler'
                      className='form-control'
                      id='searchHandler'
                      placeholder='Search Category'
                    />
                    <ul className='searchCat w-100'>
                    {searchedCategories && searchedCategories.length > 0 ? (
                        searchedCategories.map((item) => (
                          <li key={item._id} onClick={() => addCategories(item._id)}>
                            {item.name}
                          </li>
                        ))
                      ) : (
                        <li></li>
                      )}
                      <li>
                        
                      </li>
                    </ul>
                  </div>


                  <div className='col-12'>
              {
                categories?.categories && categories?.categories.length > 0 ?

                <ul className='ul'>
                  {categories?.categories.map((item,index) => (
                      <li value={item._id} key={item._id}>
                        {item.name}  - <a className='text-danger' onClick={() => deleteHandler(item._id)}>Delete</a>
                      </li>
                    ))}
                </ul>

              :

              <p>No categories</p>

              }
              
            </div>

                  

           


    </>
  )

}

export default Category