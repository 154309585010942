import React from 'react'

const Inventory = ({ quantity, sku, setSKU, setQuantity }) => {
  return (
    <div className='card mb-3'>
      <div className='card-header'>Inventory</div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-6'>
            <div className='mb-3'>
              <label htmlFor='sku' className='form-label'>
                SKU (Stock Keeping Unit)
              </label>
              <input
                type='text'
                onChange={(e) => setSKU(e.target.value)}
                name='sku'
                className='form-control'
                id='sku'
                placeholder=''
                defaultValue={sku}
              />
            </div>
          </div>
          <div className='col-6'>
            <div className='mb-3'>
              <label htmlFor='quantity' className='form-label'>
                Quantity
              </label>
              <input
                onChange={(e) => setQuantity(e.target.value)}
                type='number'
                name='quantity'
                className='form-control'
                id='quantity'
                placeholder=''
                defaultValue={quantity}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Inventory
