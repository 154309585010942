import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Toast } from "../../../services/Toast";

const Variants = ({ pId, slug, groupId }) => {
  const apiUrl = process.env.REACT_APP_API_URL;

  const [optionsData, setOptionsData] = useState([])
  const [options, setOptions] = useState([])
  const [variants, setVariants] = useState([])
  const [loading, setLoading] = useState(true)
  const [editVariant, setEditVariant] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(null) // State to hold the selected index
  const [isEdit, setIsEdit] = useState(false);
  
  function updateVariantHandler(e) {
    setEditVariant({ ...editVariant, [e.target.name]: e.target.value })
  }

  function handleVariant(e) {
    setOptionsData({ ...optionsData, [e.target.name]: e.target.value })
  }

  function updateVariant(index) {
    const updatedOptions = [...options] // Create a copy of the options array
    updatedOptions[index] = editVariant // Update the variant at the specified index
    setOptions(updatedOptions); // Update the options state with the updated array
    updateProductVariant(updatedOptions);
    setSelectedIndex(null); // Reset the selected index
    setEditVariant({}); // Reset the editVariant state
  }

  function createVariant() {
    if (optionsData) {
      setOptions([...options, optionsData])
    }
  }

  function store() {
    try {
      axios
        .post(`${apiUrl}/api/products/variant/create/${pId}`, {
          productId: pId,
          url: slug,
          optionsData: options,
          groupId: groupId,
        })
        .then(() => {
          Toast({ type: 'success', message: 'Variant created successfully!' });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        })
    } catch (err) {
      console.error(err);
    }
  }

  function updateProductVariant(updatedOptions,isDeleted) {
    try { 
      axios
        .put(`${apiUrl}/api/products/variant/update/${pId}`, {
          productId: pId,
          url: slug,
          optionsData: updatedOptions,
          groupId: groupId,
        })
        .then((response) => {
          if (isDeleted) {
            Toast({ type: 'success', message: 'Variant deleted successfully!' });
          }else{
            Toast({ type: 'success', message: 'Variant updated successfully!' });
          }
          
        })
        .catch((error) => {
          Toast({ type: 'error', message: error });
        })
    } catch (err) {
      console.error(err)
    }
  }

  function deleteVariant(index) {
    const updatedOptions = [...options]
    updatedOptions.splice(index, 1);
    setOptions(updatedOptions);
    updateProductVariant(updatedOptions,true);
  }

  useEffect(() => {
    async function getProductVariant() {
      try {
        const response = await axios.get(`${apiUrl}/api/products/variant/${pId}`, {
          timeout: 8000,
          headers: {
            'Content-Type': 'application/json',
          },
        })
        setVariants(response.data[0]);
        setOptions(response.data[0].optionsData);
        console.log(response.data[0],'1');

      } catch (err) {
        console.error(err);
      } finally{
        setLoading(false);
      }
    }

    getProductVariant()
  }, [])

  return (
    <>
      <div className='card isVariant'>
        <div className='card-header'>
          Variants
          <br />
          <p className='mb-0'>
          {!isEdit ? 'Create new variant' : 'Update Variant'}
          </p>
        </div>


        <div>
          <div className='mt-3 mb-5'>

          {!isEdit ? 
            <input className='form-control mb-3' name='variantName' type='text' placeholder='Option Name'
              onChange={(e) => handleVariant(e)}
            />
            :
            <input className='form-control mb-3' name='variantName' type='text' placeholder='Option name'
              value={editVariant.variantName || ''}
              onChange={(e) => updateVariantHandler(e)}
            />
          }

          {!isEdit ? 
            <input
              className='form-control mb-3'
              name='value'
              type='text'
              placeholder='Option Value'
              onChange={(e) => handleVariant(e)}
            />
            :
            <input
              className='form-control'
              name='value'
              placeholder='Option Value'
              value={editVariant.value || ''}
              onChange={(e) => updateVariantHandler(e)}
            />
          }


            {!isEdit ? 

              <>
                <button type='button' className='btn btn-secondary me-2' onClick={createVariant}>Add</button>
                {!variants || options.variants === 0 ? (
                  <button type='button' className='btn btn-secondary' onClick={store}>Create</button>
                ) : (<button type='button' className='btn btn-success' onClick={updateVariant}>Save</button>)}
              </>

              :

              <>
                <button type='button' className='btn btn-success me-2' onClick={() => updateVariant(selectedIndex)}>Update</button>
                <button type='button' className='btn btn-danger' onClick={() => setIsEdit(false)}>Cancel</button>
              </>

            }
            

          </div>
        </div>
      </div>

      <ul className='list-group variants-admin'>

        <div>
      {loading ? (
        <p>Loading Variants...</p>
      ) : (
        <ul className='list-group'>
          {options.map((item, i) => (
            <li key={i} className='list-group-item'>
              <span>
                <p>Name: {item.variantName} </p>
                <p>Value: {item.value} </p>
              </span>
              <span>
                <button
                  className='btn btn-secondary me-2'
                  onClick={() => {
                    setEditVariant({
                      variantName: item.variantName,
                      value: item.value,
                    });
                    setSelectedIndex(i);
                    setIsEdit(true);
                  }}
                  type='button'
                >
                  Edit
                </button>
                <button
                  type='button'
                  className='btn btn-primary'
                  onClick={() => deleteVariant(i)}
                >
                  Delete
                </button>
              </span>
            </li>
          ))}
        </ul>
      )}
    </div>
      </ul>


    </>
  )
}

export default Variants
