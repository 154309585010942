import React from 'react'

const Sidebar = ({searchCollections,searchedCategories,handleCategoryItemClick}) => {

    return (
        <div className="offcanvas offcanvas-start" tabIndex="-1" id="createCategorySidebar" aria-labelledby="createCategorySidebarLabel">
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="createCategorySidebarLabel">Add a category</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">

            <div className='mb-3'>
                <label htmlFor='menuName' className='form-label'>
                    Category name
                </label>
                <input
                    type='text'
                    className='form-control'
                    id='menuName'
                    placeholder='Category Name'
                    name='menuName'
                    onChange={(e) => searchCollections(e)}
                />
            </div>

            <ul className='searchCat w-100'>
                {searchedCategories && searchedCategories.length > 0 && (
                <>
                    {searchedCategories?.map((category) => (
                    <li
                        key={category._id}
                        onClick={() => handleCategoryItemClick(category._id, true)}
                    >
                        {category.name}
                    </li>
                    ))}
                </>
                )}
            </ul>

        </div>
      </div>
    )

}

export default Sidebar