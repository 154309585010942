import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Toast
export const Toast = ({ type = "success", message, time = 2000 }) => {
    if (Array.isArray(message)) {
        message.forEach((item) => {
            toast[type](item.message, { autoClose: time });
        });
    } else {
        toast[type](message, { autoClose: time });
    }
};
