import React, { useState,useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux'
import { Toast } from "../../../services/Toast";
import { createCategory } from '../../../actions/categoryActions'

const CategoryCreateScreen = ({ match, location, history }) => {
  const dispatch = useDispatch()
  const categoryCreate = useSelector((state) => state.categoryCreate)
  const { loading, success, category } = categoryCreate

  const [categoryName, setCategoryName] = useState('')
  const [slug, setSlug] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])

  const handlePaste = (event) => {
    event.preventDefault()
    const pastedValue = event.clipboardData.getData('text')
    const transformedValue = pastedValue
      .replace(/ {2,}/g, ' ') // Replace double spaces with a single space
      .replace(/ /g, '-') // Replace spaces with hyphens
      .replace(/[()]/g, '') // Remove brackets
      .toLowerCase() // Convert to lowercase
      .replace(/,/g, '') // Delete commas
    // Update the slug state with the transformed value
    setSlug(transformedValue)
  }

  function tagsHandler(e) {
    const inputTags = e.target.value.split(',')
    setTags(inputTags.map((tag) => tag.trim()))
  }

  function createCategoryHandler() {
    const category = {
      name: categoryName,
      slug: slug,
      description: description,
      tags: tags,
    }

    dispatch(createCategory(category))
  }

  function removeTag(index) {
    const updatedTags = [...tags]
    updatedTags.splice(index, 1)
    setTags(updatedTags)

    // Clear tag from textarea
    const textarea = document.getElementById('tags')
    const textareaValue = textarea.value
    const tagToRemove = tags[index]
    const updatedTextareaValue = textareaValue
      .replace(tagToRemove, '')
      .replace(/,,/g, ',')
    textarea.value = updatedTextareaValue
  }

  useEffect(() => {
    if (success) {
      history.push(`/admin/category/edit/${category[0].slug}`);
      Toast({ type: 'success', message: 'Category created successfully!' });
    }
    if (category) {
      console.log(category,'category');
    }
  }, [success, history, category])

  return (
    <>
      <section className='mt-5 mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='mb-1'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input
                      onChange={(e) => setCategoryName(e.target.value)}
                      type='text'
                      autoComplete='off'
                      name='name'
                      className='form-control'
                      id='name'
                      placeholder='Category Name'
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='slug' className='form-label'>
                      Slug
                    </label>
                    <input
                      onPaste={handlePaste}
                      onChange={(e) => setSlug(e.target.value)}
                      type='text'
                      name='slug'
                      className='form-control'
                      id='slug'
                      placeholder='Category Slug'
                      defaultValue={slug}
                    />
                  </div>

                  <div className='mb-1'>
                    <label htmlFor='description' className='form-label'>
                      Description
                    </label>
                    <input
                      onChange={(e) => setDescription(e.target.value)}
                      type='text'
                      autoComplete='off'
                      name='description'
                      className='form-control'
                      id='description'
                      placeholder='A Description'
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='tags' className='form-label'>
                      Tags
                    </label>
                    <textarea
                      className='form-control mb-2'
                      id='tags'
                      rows='3'
                      name='tags'
                      onChange={tagsHandler}
                    ></textarea>

                    <div id='tags'>
                      {tags.map((tag, index) => (
                        <button
                          key={index}
                          type='button'
                          className='btn btn-primary tags-btn'
                          onClick={() => removeTag(index)}
                        >
                          {tag}{' '}
                          <span className='badge text-bg-secondary'>X</span>
                        </button>
                      ))}
                    </div>
                  </div>

                  <button
                    disabled={loading}
                    className='btn btn-success'
                    onClick={createCategoryHandler}
                  >
                    {loading ? 'Creating...' : 'Create Category'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CategoryCreateScreen
