import React, { useState, useEffect } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'

const Login = ({ setHideModal }) => {
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, success, error } = userLogin

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, phone, password))
  }

  return (
    <>
      {success && setHideModal(true)}

      <Form onSubmit={submitHandler}>
        <Form.Group controlId='phone' className='mb-4'>
          <Form.Label>Enter Mobile Number</Form.Label>
          <Form.Control
            type='number'
            placeholder='Mobile Number'
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='password' className='mb-4'>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type='password'
            placeholder='Enter password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete='new-password'
          ></Form.Control>
        </Form.Group>

        <Button type='submit' variant='primary'>
          Login
        </Button>
      </Form>
    </>
  )
}

export default Login
