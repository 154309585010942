import React from 'react'
import { Link } from 'react-router-dom'
const Nav = ({
  loading,
  name,
  error,
  errorMessage,
  submitHandler,
  slug,
}) => {
  return (
    <>

      <nav className='navbar navbar-expand-lg bg-light sticky-top mb-4 z-3'>
        <div className='container'>
          <div>
            <Link
              to='/admin/productlist'
              className='btn btn-outline-primary me-3'
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                strokeWidth='1.5'
                stroke='currentColor'
                className='w-6 h-6 me-2'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  d='M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3'
                />
              </svg>
              Go Back
            </Link>

            <a className='navbar-brand'>{name}</a>
          </div>

          <div>
            <a
              href={`${process.env.REACT_APP_SITE_URL}/products/${slug}`}
              target='_blank'
              className='btn btn-outline'
            >
              View
            </a>

            <button
              onClick={(e) => submitHandler(e)}
              className='btn btn-success'
              type='submit'
            >
              {loading ? 'Saving...' : 'Save'}
            </button>

            {/* {
              dataChanged ? 
              <button
                onClick={(e) => submitHandler(e)}
                className='btn btn-success'
                type='submit'
            >
              {loading ? 'Saving...' : 'Save'}
            </button>
            :
            <button onClick={(e) => submitHandler(e)} className='btn btn-disabled' disabled type='button'>
              {loading ? 'Saving...' : 'Save'}
            </button>
            } */}

          

          </div>
        </div>
      </nav>
      {error ? (
        <div className='container'>
          <div
            className='alert alert-danger d-flex align-items-center'
            role='alert'
          >
            <div>{errorMessage}</div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Nav
