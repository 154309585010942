import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCollectionById,
} from '../../../actions/collectionAction'
const CollectionEditScreen = ({ match }) => {

  const pId = match.params.id;
  const dispatch = useDispatch()
  
  const [products,setProducts] = useState([]);
  const [productIds,setProductIds] = useState([]);
  
  const [searchedProducts,setSearchedProducts] = useState([])
  const [searchedCategories,setSearchedCategories] = useState([])
  const [categoryLink, setCategoryLink] = useState('');

  const [collectionName, setCollectionName] = useState('')
  const [slug, setSlug] = useState('')
  const [description, setDescription] = useState('')
  const [tags, setTags] = useState([])
  const [collectionId, setCollectionId] = useState('')

  const getCollection = useSelector((state) => state.getCollection)
  const { loading, error, fetchCollection } = getCollection

  const [newProductAdded,setNewProductAdded] = useState(false)

  function searchHandler(e){
    const { value } = e.target
    fetchProductsByName(value)
  }
  const fetchProductsByName = async (name) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/products/name/${name}`)
      const data = await response.json()
      setSearchedProducts(data)
    } catch (error) {
      console.error('Error fetching products:', error)
    }
  }

  function categorySearchHandler(e){
    const { value } = e.target
    fetchCategoriesByName(value)
  }
  const fetchCategoriesByName = async (name) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/category/keyword/search?keyword=${name}`);
      const data = await response.json();
      setSearchedCategories(data);
    } catch (error) {
      console.error('Error fetching category:', error);
    }
  }
  
async function addProducts(productId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/product/store/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        productId: productId,
        collectionId: collectionId,
      }),
    });

    if (!response.ok) {
      // Handle the error response
      const errorData = await response.json(); // Extract error message from JSON response
      throw new Error(errorData.message || 'Failed to add product to collection');
    }
    else{
      setNewProductAdded(true)
    }

  } catch (error) {
    alert(error.message);
  }
}

  async function deleteAProductHandler (productId) {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/product/store/${productId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productId: productId,
          collectionId: collectionId,
        }),
      });
  
      if (!response.ok) {
        // Handle the error response
        const errorData = await response.json(); // Extract error message from JSON response
        throw new Error(errorData.message || 'Failed to add product to category');
      }
      else{
        setNewProductAdded(true)
      }
  
    } catch (error) {
      alert(error.message);
    }
  }
  

useEffect(() => {
  dispatch(getCollectionById(pId));
}, [newProductAdded]);

async function fetchProducts(id){

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/products/${id}`,{
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch products');
    }
    else{
      
      const data = await response.json();
      console.log(data);
      setProducts(data);
    }

  } catch (error) {
    
  }

}


const updateHandler = async (id) => {

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/collection/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: collectionName,
        slug: slug,
        description: description,
        categoryLink: categoryLink,
      }),
    });

    if (!response.ok) {
      // Handle the error response
      const errorData = await response.json(); // Extract error message from JSON response
      throw new Error(errorData.message || 'Failed to add product to collection');
    }
    else{
      setNewProductAdded(true)
    }

  } catch (error) {
  }

}
const addCategory = (slug) => {
  setCategoryLink(slug);
};

useEffect(() => {
  if (fetchCollection) {
    setCollectionName(fetchCollection.name || ''); // Ensure fallback to empty string
    setSlug(fetchCollection.slug || ''); // Ensure fallback to empty string
    setDescription(fetchCollection.description || ''); // Ensure fallback to empty string
    setTags(fetchCollection.tags || []);
    setCollectionId(fetchCollection._id || '');
    setProductIds(fetchCollection.products);
    setCategoryLink(fetchCollection.categoryLink || '')

    if (productIds) {
      if (productIds.length > 0){
        fetchProducts(collectionId);
      }
    }

  }
}, [fetchCollection,productIds]);

  return (
    <>

      <section className='mt-5 mb-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card mb-3'>
                <div className='card-body'>
                  <div className='mb-2'>
                    <label htmlFor='name' className='form-label'>
                      Name
                    </label>
                    <input
                      onChange={(e) => setCollectionName(e.target.value)}
                      value={collectionName}
                      type='text'
                      autoComplete='off'
                      name='name'
                      className='form-control'
                      id='name'
                      placeholder='Collection Name'
                    />
                  </div>

                  <div className='mb-2'>
                    <label htmlFor='slug' className='form-label'>
                      Slug
                    </label>
                    <input
                      onChange={(e) => setSlug(e.target.value)}
                      value={slug}
                      type='text'
                      autoComplete='off'
                      name='slug'
                      className='form-control'
                      id='slug'
                      placeholder='Collection Slug'
                    />
                  </div>

                  <div className='mb-2'>
                    <label htmlFor='description' className='form-label'>
                      Description
                    </label>
                    <input
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                      type='text'
                      autoComplete='off'
                      name='description'
                      className='form-control'
                      id='description'
                      placeholder='A Description'
                    />
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='tags' className='form-label'>
                      Add Category
                    </label>
                    <input
                      onChange={(e) => categorySearchHandler(e)}
                      type='text'
                      autoComplete='off'
                      name='categorySearchHandler'
                      className='form-control'
                      id='categorySearchHandler'
                      placeholder='Search Categories'
                      value={categoryLink}
                    />
                    <ul className='searchCat w-100'>
                      {searchedCategories && searchedCategories.length > 0 ? (
                        searchedCategories.map((item) => (
                          <li key={item.slug} onClick={() => addCategory(item.slug)}>
                            {item.name}
                          </li>
                        ))
                      ) : (
                        null
                      )}
                    </ul>
                  </div>

                  <div className='mb-3'>
                    <label htmlFor='tags' className='form-label'>
                      Add Products
                    </label>
                    <input
                      onChange={(e) => searchHandler(e)}
                      type='search'
                      autoComplete='off'
                      name='searchHandler'
                      className='form-control'
                      id='searchHandler'
                      placeholder='Search Products'
                    />
                    <ul className='searchCat w-100'>
                    {searchedProducts && searchedProducts.length > 0 ? (
                        searchedProducts.map((item) => (
                          <li key={item._id} onClick={() => addProducts(item._id)}>
                            {item.name}
                          </li>
                        ))
                      ) : (
                        <li>No products found</li>
                      )}
                      <li>
                        
                      </li>
                    </ul>
                  </div>

                  <button
                    className='btn btn-success'
                    onClick={() => updateHandler(collectionId)}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              {
                products?.length > 0 ?

                <ul className='ul'>
                  {products?.map((item,index) => (
                    <div class="card">
                      <div class="card-body d-flex align-items-center">

                        <div className='me-3'>
                          {index + 1}. <a href={`/admin/product/${item._id}/edit`}><span>{item.name}</span></a>
                        </div>
                        <a onClick={() => deleteAProductHandler(item._id)} className='text-danger'>Delete</a>

                      </div>
                    </div>

                    ))}
                </ul>

              :

              <p>No products</p>

              }
              
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default CollectionEditScreen
