import {
  MENU_LIST_REQUEST,
  MENU_LIST_SUCCESS,
  MENU_LIST_FAIL,
  MENU_GET_REQUEST,
  MENU_GET_SUCCESS,
  MENU_GET_FAIL,
  MENU_UPDATE_REQUEST,
  MENU_UPDATE_SUCCESS,
  MENU_UPDATE_FAIL,
} from '../constants/menuConstants'

export const allMenusReducer = (
  state = { loading: false, error: null, menus: [] },
  action
) => {
  switch (action.type) {
    case MENU_LIST_REQUEST:
      return { ...state, loading: true }
    case MENU_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        menus: action.payload,
      }
    case MENU_LIST_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const menuByIdReducer = (
  state = { loading: false, error: null, menu: [] },
  action
) => {
  switch (action.type) {
    case MENU_GET_REQUEST:
      return { ...state, loading: true }
    case MENU_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: action.payload,
      }
    case MENU_GET_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateMenuReducer = (
  state = { loading: false, error: null, menu: [] },
  action
) => {
  switch (action.type) {
    case MENU_UPDATE_REQUEST:
      return { ...state, loading: true }
    case MENU_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        menu: action.payload,
      }
    case MENU_UPDATE_FAIL:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}
