import React, { useEffect, useState } from 'react'

import axios from 'axios'
import SearchBox from './SearchBox'
import { listCategories } from '../actions/categoryActions'
import { useDispatch, useSelector } from 'react-redux';

const Menu = () => {
  const [menu,setMenu] = useState([])
  const dispatch = useDispatch()
  const categoryList = useSelector((state) => state.categoryList)
  const { loading, error, categories } = categoryList;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    dispatch(listCategories())
    axios
    .get(`${apiUrl}/api/menu/6498246a1ccc1a67874ee66c`)
    .then((response) => {
      setMenu(response.data.menuList)
    })
    .catch((error) => {
      console.error('Error making API request:', error)
    })
  }, [dispatch])

  return (
    <>


      <nav className='navbar navbar-expand-lg bg-white secondary-menu'>
        <div className='container'>
          <div className='collapse navbar-collapse' id='menu'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>

            <li>
                          <a className='dropdown-item' href='/admin/userlist'>
                            Users
                          </a>
                        </li>

                        <li>
                          <a
                            className='dropdown-item'
                            href='/admin/categoryList'
                          >
                            Categories
                          </a>
                        </li>

                        <li>
                          <a
                            className='dropdown-item'
                            href='/admin/collections'
                          >
                            Collections
                          </a>
                        </li>

                        <li>
                          <a
                            className='dropdown-item'
                            href='/admin/productlist'
                          >
                            Products
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/orderlist'>
                            Orders
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/menus'>
                            Menus
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/mobile-menu'>
                            Mobile Menu
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/brands'>
                            Brands
                          </a>
                        </li>
                       

            </ul>
          </div>
          <SearchBox />
        </div>
      </nav>
    </>
  )
}

export default Menu
