import React, { useState } from 'react'
import styles from '../../components/category/filter.module.css'

const Filter = ({
  handlePriceSliderChange,
  handlePriceRangeChange,
  sliderValue,
  productsLoading,
  productsError,
  error,
  products,
  handleBrandCheckboxChange,
  selectedBrands,
  handleColorCheckboxChange,
  selectedColors,
  colors,
  brands,
  isPriceSelected,
  selectedPriceRange,
}) => {
  return (
    <>
      <div className='card filters'>
        <div className='card-body'>
          <h3 className='card-title'>Filter</h3>
        </div>
        <ul className='list-group list-group-flush'>
          <li className='list-group-item'>
            <h4>Price</h4>

            <div className='mt-3'>
              <input
                id='priceRange'
                type='range'
                className='form-range'
                min='10000'
                max='40000'
                step='10000'
                defaultValue={sliderValue}
                onChange={(e) => handlePriceSliderChange(e)}
              />
            </div>
            <div className={styles.filter}>
              <select
                className='form-select'
                aria-label='Default select example'
              >
                <option selected>Min</option>
              </select>

              <div>to</div>

              <select
                id='priceSelect'
                className='form-select'
                aria-label='Default select example'
                onChange={(e) => handlePriceRangeChange(e)}
              >
                <option selected value='40000'>
                  ₹40,000+
                </option>
                <option value='30000'>₹30,000</option>
                <option value='20000'>₹20,000</option>
                <option value='10000'>₹10,000</option>
              </select>
            </div>
          </li>

          {isPriceSelected ? (
            <li className='list-group-item'>
              <h4>Brand</h4>
              {products.brands?.map((brand, i) => (
                <div className='form-check' key={i}>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={`${brand}-${i}`}
                    readOnly
                    onChange={(e) => handleBrandCheckboxChange(e, brand)}
                  />
                  <label className='form-check-label' htmlFor={`${brand}-${i}`}>
                    {brand}
                  </label>
                </div>
              ))}
            </li>
          ) : (
            <li className='list-group-item'>
              <h4>Brand</h4>
              {brands?.map((brand, i) => (
                <div className='form-check' key={i}>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id={`${brand}-${i}`}
                    readOnly
                    onChange={(e) => handleBrandCheckboxChange(e, brand)}
                  />
                  <label className='form-check-label' htmlFor={`${brand}-${i}`}>
                    {brand}
                  </label>
                </div>
              ))}
            </li>
          )}

          <li className='list-group-item'>
            <h4>Colors</h4>
            {products.colors?.map((color, i) => (
              <div className='form-check' key={i}>
                <input
                  id={color}
                  className='form-check-input'
                  type='checkbox'
                  onChange={(e) => handleColorCheckboxChange(e, color)}
                  readOnly
                />
                <label htmlFor={color} className='form-check-label'>
                  {color}
                </label>
              </div>
            ))}
          </li>
        </ul>
      </div>
    </>
  )
}

export default Filter
