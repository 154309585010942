import React, { useRef, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Button, Form } from 'react-bootstrap'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import Rating from '../components/Rating'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Meta from '../components/Meta'
import axios from 'axios'
import {
  listProductDetails,
  createProductReview,
} from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'
import { formatCurrency, calculateDiscountPercentage } from '../utils/currency'
const apiUrl = process.env.REACT_APP_API_URL;
const ProductScreen = ({ history, match }) => {
  const myRef = useRef()
  const [qty, setQty] = useState(1)
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')

  const dispatch = useDispatch()

  const productDetails = useSelector((state) => state.productDetails)
  const { loading, error, product } = productDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const productReviewCreate = useSelector((state) => state.productReviewCreate)
  const {
    success: successProductReview,
    loading: loadingProductReview,
    error: errorProductReview,
  } = productReviewCreate

  var featuresCol = 'col-xl-6 col-lg-6 col-md-12 mb-3'
  const [options, setOptions] = useState('')

  const addToCartHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`)
  }

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(
      createProductReview(match.params.id, {
        rating,
        comment,
      })
    )
  }

  const refs = useRef([])
  refs.current = []

  const originalPrice = product && product.wasPrice
  const discountedPrice = product && product.price

  const formattedPrice =
    product && product.price ? formatCurrency(product.price) : null

  const formattedWasPrice =
    product && product.wasPrice ? formatCurrency(product.wasPrice) : null

  const discountPercentage = calculateDiscountPercentage(
    originalPrice,
    discountedPrice
  )

  useEffect(() => {
    if (successProductReview) {
      setRating(0)
      setComment('')
    }

    dispatch(listProductDetails(match.params.id))
    dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
  }, [dispatch, match, successProductReview])

  useEffect(() => {
    if (product) {
      axios
        .get(`${apiUrl}/api/products/options/${product._id}`)
        .then((res) => {
          setOptions(res.data)
        })
        .catch((error) => {})
    }
  }, [product])

  const [isLiked, setIsLiked] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const handleLikeToggle = () => {
    setIsLiked(!isLiked)
    setShowAlert(true)
  }

  useEffect(() => {
    let timeoutId

    if (showAlert) {
      timeoutId = setTimeout(() => {
        setShowAlert(false)
      }, 2000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [showAlert])

  return (
    <>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          {product && product.seo && product.seo.length > 0 && (
            <Meta title={product.seo[0].pageTitle} />
          )}

          <div className=''>
            <section className='section product-section'>
              <div className='container'>
                <div className='row product-detail'>
                  <div className='col-xl-4 col-lg-4 col-md-6 col-sm-6 text-center'>
                    <div className='sticky-top'>
                      <div className='productSlider d-none'>
                        <div className='row rowone'>
                          <div className='col-lg-12 colone'>
                            <div className='fluid'>
                              <div className='fluid__image-container'></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <ProductSlider image={product} /> */}
                      {/* <ProductSlider image={product} /> */}

                      <>
                        <div
                          id='cart--btns-bottom'
                          className='cart--btns mt-4 d-none'
                        >
                          <button className='btn buy-now'>Buy Now</button>
                          <button
                            className='btn btn-primary add-to-cart'
                            onClick={addToCartHandler}
                            type='button'
                            disabled={product.countInStock === 0}
                          >
                            Add to Cart
                          </button>

                          {/* <button className="btn btn-primary add-to-cart" onClick={(e) => removeItem(product.id)}>Remove from Cart</button> */}
                          {/* <button className="btn btn-primary add-to-cart" onClick={(e) => getCart(e)}>Get Cart</button> */}
                        </div>
                      </>
                    </div>
                  </div>

                  <div
                    className='col-xl-8 col-lg-8 col-md-6 col-sm-6 product-detail-info'
                    id=''
                  >
                    <div id='myPortal' />
                    <>
                      <ul className='breadcrumb'>
                        <li>Home</li>
                        <li className='right-arrow'>/</li>
                        <li>
                          <Link to='/category/mobiles'>Mobiles</Link>
                        </li>
                        <li className='right-arrow'>/</li>
                        <li className='blackActive'>{product.name}</li>
                      </ul>
                      <h1 className='pt-0 product-title'>{product.name}</h1>

                      <div className='rating'>
                        <img
                          width='32'
                          height='32'
                          alt=''
                          className='star'
                          src='/star.png'
                        ></img>
                        <img
                          width='32'
                          height='32'
                          alt=''
                          className='star'
                          src='/star.png'
                        ></img>
                        <img
                          width='32'
                          height='32'
                          alt=''
                          className='star'
                          src='/star.png'
                        ></img>
                        <img
                          width='32'
                          height='32'
                          alt=''
                          className='star'
                          src='/star.png'
                        ></img>
                        <img
                          width='32'
                          height='32'
                          alt=''
                          className='star'
                          src='/star.png'
                        ></img>
                        <span className=''>4.2</span>
                        2,740 Ratings & 209 Reviews
                      </div>
                      <p>
                        <span className='price'>{formattedPrice}</span>
                        <span className='price slashed'>
                          {formattedWasPrice}
                        </span>
                        <span className='offer'>{discountPercentage}% Off</span>
                      </p>
                      {/* <Offers /> */}
                      <div className='know-more-div'>
                        <img width='32' height='32' alt='' src='/sam.jpg' />
                        <p className='m-0'>
                          1 Year Warranty Provided By the Manufacturer from Date
                          of Purchase{' '}
                          <span className='know-more'>Know More</span>
                        </p>
                      </div>

                      <div className='row variant-row'>
                        {options &&
                          options
                            .slice()
                            .sort((a, b) =>
                              a.variantName.localeCompare(
                                b.variantName,
                                undefined,
                                {
                                  sensitivity: 'base',
                                }
                              )
                            )
                            .map((variant, index) => (
                              <div key={index} className='col-6 mb-4'>
                                <strong className='mb-2'>
                                  {variant.variantName} :{' '}
                                </strong>
                                <br></br>
                                {variant.variantValue
                                  ?.slice()
                                  .sort((a, b) =>
                                    a.value.localeCompare(b.value, undefined, {
                                      sensitivity: 'base',
                                    })
                                  )
                                  .map((option, optionIndex) => (
                                    <Link
                                      to={`/product/${option.slug}`}
                                      key={optionIndex}
                                      onClick={(e) => {
                                        if (option.isSelected) {
                                          e.preventDefault()
                                        }
                                      }}
                                      className={`${
                                        option.isSelected ? 'success-link' : ''
                                      }`}
                                    >
                                      <button
                                        className={`btn ${
                                          option.isSelected
                                            ? 'btn-outline-success active me-2'
                                            : 'btn-outline-secondary btn me-2'
                                        }`}
                                      >
                                        {option.value}
                                      </button>
                                    </Link>
                                  ))}
                              </div>
                            ))}
                      </div>

                      <div className='row'>
                        <div
                          ref={myRef}
                          id='cart--btns'
                          className='cart--btns mt-4'
                        >
                          <button className='btn buy-now'>Buy Now</button>
                          <button
                            className='btn btn-primary add-to-cart'
                            onClick={addToCartHandler}
                            type='button'
                            disabled={product.countInStock === 0}
                          >
                            Add to Cart
                          </button>
                        </div>
                      </div>
                      <div id='uptop' className='row variant-row '>
                        <div className='col-lg-6 delivery-col'>
                          <h5>Delivery</h5>
                          <div className='input-group flex-nowrap'>
                            <span
                              className='input-group-text'
                              id='addon-wrapping'
                            >
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                className='h-5 w-5'
                                viewBox='0 0 20 20'
                                fill='currentColor'
                              >
                                <path
                                  fillRule='evenodd'
                                  d='M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z'
                                  clipRule='evenodd'
                                />
                              </svg>
                            </span>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Enter Your Pincode'
                              aria-label='Delivery'
                              aria-describedby='addon-wrapping'
                            />
                          </div>
                          <p>Enter pincode for exact delivery dates/charges</p>
                        </div>
                      </div>
                      <div className='row variant-row'>
                        <div className='col-lg-12'>
                          <h5>Highlights</h5>
                          <ul className='highlights'>
                            <li>
                              4 GB RAM | 128 GB ROM | Expandable Upto 1 TB
                            </li>
                            <li>16.76 cm (6.6 inch) Full HD+ Display</li>
                            <li>50MP + 5MP + 2MP | 8MP Front Camera</li>
                            <li>6000 mAh Lithium Ion Battery</li>
                            <li>Exynos 850 Processor</li>
                          </ul>
                        </div>
                      </div>
                      <div className='row variant-row'>
                        <div className='col-lg-12'>
                          <h5>Product description</h5>
                          <p className='desc'>{product.description}</p>
                        </div>
                      </div>
                    </>

                    <div className='row features'>
                      {product.features && product.features.length ? (
                        <h3>Features</h3>
                      ) : (
                        ''
                      )}

                      {product.features?.map((item, index) => {
                        return (
                          <React.Fragment key={item.id}>
                            <div className={featuresCol}>
                              <h4>{item.heading}</h4>
                              <p>{item.description}</p>
                            </div>
                          </React.Fragment>
                        )
                      })}
                    </div>

                    <div className='specs-table'>
                      <h3>Specifications</h3>

                      {product.specifications?.map((item) => {
                        return (
                          <div>
                            <h4>{item.heading}</h4>
                            <table className='specs'>
                              <tbody>
                                {item.specification?.map((spec) => {
                                  return (
                                    <tr>
                                      <td scope='row'>{spec.title}</td>
                                      <td scope='row'>{spec.description}</td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        )
                      })}
                    </div>

                    <div className='share-panel'>
                      <div>
                        <div
                          className={`wishlist-heart ${isLiked ? 'liked' : ''}`}
                          onClick={handleLikeToggle}
                        >
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='h-6 w-6 heartss'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                            strokeWidth='2'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
                            />
                          </svg>
                        </div>

                        <div className='alert'>
                          {showAlert && isLiked && (
                            <div className='alert-message'>
                              Item added to wishlist!
                            </div>
                          )}
                          {showAlert && !isLiked && (
                            <div className='alert-message'>
                              Item removed from wishlist!
                            </div>
                          )}
                        </div>
                      </div>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-6 w-6'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke='currentColor'
                        strokeWidth={2}
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z'
                        />
                      </svg>
                    </div>

                    <div>
                      <div
                        className={`wishlist-heart ${isLiked ? 'liked' : ''}`}
                        onClick={handleLikeToggle}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          className='h-6 w-6 heartss'
                          fill='none'
                          viewBox='0 0 24 24'
                          stroke='currentColor'
                          strokeWidth='2'
                        >
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            d='M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z'
                          />
                        </svg>
                      </div>

                      {showAlert && isLiked && (
                        <div className='alert-message'>
                          Item added to wishlist!
                        </div>
                      )}
                      {showAlert && !isLiked && (
                        <div className='alert-message'>
                          Item removed from wishlist!
                        </div>
                      )}
                    </div>
                  </div>
                  {/* col 8 ends  */}
                </div>
              </div>
            </section>

            <section className='pt-0 secc section m-mb-0 m-mt-0'>
              <div className='container'>
                <div className='row'>
                  <h3>You may also like</h3>

                  <Splide
                    aria-label='My Favorite Images'
                    options={{
                      type: 'loop',
                      perPage: 5,
                      perMove: 1,
                      breakpoints: {
                        640: {
                          perPage: 3,
                          fixedHeight: 256,
                        },
                      },
                    }}
                  >
                    <SplideSlide>
                      <Link to='/samsung-galaxy-f13'>
                        <div className='product'>
                          <div className='product-image'>
                            <img
                              width='100%'
                              height='100%'
                              alt=''
                              className='product-img'
                              src=''
                            />
                          </div>
                          <div>
                            <p className='info'>
                              SAMSUNG Galaxy F13 (Waterfall Blue, 128 GB) (4 GB
                              RAM)
                            </p>
                            <p className='price'>
                              <span className='main-price'>2222</span>{' '}
                              <span className='original-price'>3222 </span>
                              <br />
                              <span className='discount'>
                                You Save (18% off)
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </SplideSlide>

                    <SplideSlide>
                      <Link to='/samsung-galaxy-f13'>
                        <div className='product'>
                          <div className='product-image'>
                            <img
                              width='100%'
                              height='100%'
                              alt=''
                              className='product-img'
                              src=''
                            />
                          </div>
                          <div>
                            <p className='info'>
                              APPLE iPhone 12 (Red, 128 GB) <br />
                              <br />
                            </p>
                            <p className='price'>
                              <span className='main-price'>₹65,499</span>{' '}
                              <span className='original-price'>70,990 </span>
                              <br />
                              <span className='discount'>
                                You Save (18% off)
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </SplideSlide>

                    <SplideSlide>
                      <Link to='/samsung-galaxy-f13'>
                        <div className='product'>
                          <div className='product-image'>
                            <img
                              width='100%'
                              height='100%'
                              className='product-img'
                              src=''
                              alt=''
                            />
                          </div>
                          <div>
                            <p className='info'>
                              vivo T1 5G (Starlight Black, 128 GB) (6 GB RAM)
                            </p>
                            <p className='price'>
                              <span className='main-price'>₹65,499</span>{' '}
                              <span className='original-price'>70,990 </span>
                              <br />
                              <span className='discount'>
                                You Save (18% off)
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </SplideSlide>

                    <SplideSlide>
                      <Link to='/samsung-galaxy-f13'>
                        <div className='product'>
                          <div className='product-image'>
                            <img
                              width='100%'
                              height='100%'
                              className='product-img'
                              src=''
                              alt=''
                            />
                          </div>
                          <div>
                            <p className='info'>
                              Redmi 9A Sport (Coral Green, 32 GB) (2 GB RAM)
                            </p>
                            <p className='price'>
                              <span className='main-price'>₹6,779</span>
                              <br />
                              <span className='discount'>
                                You Save (18% off)
                              </span>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </SplideSlide>

                    {/* </div> */}
                  </Splide>
                </div>
              </div>
            </section>

            <section>
              <div className='container'>
                <Row>
                  <Col md={6}>
                    <h2>Reviews</h2>
                    {product.reviews.length === 0 && (
                      <Message>No Reviews</Message>
                    )}
                    <ListGroup variant='flush'>
                      {product.reviews.map((review) => (
                        <ListGroup.Item key={review._id}>
                          <strong>{review.name}</strong>
                          <Rating value={review.rating} />
                          <p>{review.createdAt.substring(0, 10)}</p>
                          <p>{review.comment}</p>
                        </ListGroup.Item>
                      ))}
                      <ListGroup.Item>
                        <h2>Write a Customer Review</h2>
                        {successProductReview && (
                          <Message variant='success'>
                            Review submitted successfully
                          </Message>
                        )}
                        {loadingProductReview && <Loader />}
                        {errorProductReview && (
                          <Message variant='danger'>
                            {errorProductReview}
                          </Message>
                        )}
                        {userInfo ? (
                          <Form onSubmit={submitHandler}>
                            <Form.Group controlId='rating'>
                              <Form.Label>Rating</Form.Label>
                              <Form.Control
                                as='select'
                                value={rating}
                                onChange={(e) => setRating(e.target.value)}
                              >
                                <option value=''>Select...</option>
                                <option value='1'>1 - Poor</option>
                                <option value='2'>2 - Fair</option>
                                <option value='3'>3 - Good</option>
                                <option value='4'>4 - Very Good</option>
                                <option value='5'>5 - Excellent</option>
                              </Form.Control>
                            </Form.Group>
                            <Form.Group controlId='comment'>
                              <Form.Label>Comment</Form.Label>
                              <Form.Control
                                as='textarea'
                                row='3'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              ></Form.Control>
                            </Form.Group>
                            <Button
                              disabled={loadingProductReview}
                              type='submit'
                              variant='primary'
                            >
                              Submit
                            </Button>
                          </Form>
                        ) : (
                          <Message>
                            Please <Link to='/login'>sign in</Link> to write a
                            review{' '}
                          </Message>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </>
      )}
    </>
  )
}

export default ProductScreen
