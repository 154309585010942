export const MENU_LIST_REQUEST = 'MENU_LIST_REQUEST'
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS'
export const MENU_LIST_FAIL = 'MENU_LIST_FAIL'

export const MENU_GET_REQUEST = 'MENU_GET_REQUEST'
export const MENU_GET_SUCCESS = 'MENU_GET_SUCCESS'
export const MENU_GET_FAIL = 'MENU_GET_FAIL'

export const MENU_UPDATE_REQUEST = 'MENU_UPDATE_REQUEST'
export const MENU_UPDATE_SUCCESS = 'MENU_UPDATE_SUCCESS'
export const MENU_UPDATE_FAIL = 'MENU_UPDATE_FAIL'
