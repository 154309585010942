import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/userActions'
import Menu from './Menu'
import Modal from './Modal'
import { LinkContainer } from 'react-router-bootstrap'
import { NavDropdown } from 'react-bootstrap'

const Header = () => {
  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  return (
    <>
      <Modal />
      <header id='header'>
        <nav className='primary-bg navbar navbar-expand-lg primary-nav'>
          <div className='container'>
            <a className='navbar-brand text-white' href='/'>
              <img
                width='181'
                height='41.5'
                className='img-fluid'
                src='/logo.png'
                alt='TelefoneDeal'
              />
            </a>


            <div
              className='collapse navbar-collapse'
              id='navbarSupportedContent'
            >
              <ul className='navbar-nav ms-auto mb-2 mb-lg-0'>

                {userInfo && !userInfo.isAdmin ? (
                  <NavDropdown title={userInfo.name} id='username'>
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item onClick={logoutHandler}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <></>
                )}

                {userInfo && userInfo.isAdmin ? (
                  <>
                    <li className='nav-item dropdown'>
                      <a
                        className='nav-link dropdown-toggle'
                        href='#'
                        role='button'
                        data-bs-toggle='dropdown'
                        aria-expanded='false'
                      >
                        {userInfo.name}
                      </a>
                      <ul className='dropdown-menu'>
                        <li>
                          <a className='dropdown-item' href='/admin/userlist'>
                            Users
                          </a>
                        </li>

                        <li>
                          <a
                            className='dropdown-item'
                            href='/admin/categoryList'
                          >
                            Categories
                          </a>
                        </li>

                        <li>
                          <a
                            className='dropdown-item'
                            href='/admin/productlist'
                          >
                            Products
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/orderlist'>
                            Orders
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/menus'>
                            Menus
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/brands'>
                            Brands
                          </a>
                        </li>
                        <li>
                          <a className='dropdown-item' href='/admin/homepage'>
                            Homepage
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className='nav-item'>
                      <a className='nav-link' onClick={(e) => logoutHandler(e)}>
                        Logout
                      </a>
                    </li>
                  </>
                ) : (
                  <li className='nav-item icon-div'>
                    <a
                      className='nav-link'
                      // data-bs-toggle='modal'
                      // data-bs-target='#loginModal'
                      href='/login'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        className='h-5 w-5'
                        viewBox='0 0 20 20'
                        fill='currentColor'
                      >
                        <path
                          fillRule='evenodd'
                          d='M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z'
                          clipRule='evenodd'
                        />
                      </svg>{' '}
                      Login
                    </a>
                  </li>
                )}

              </ul>
            </div>
          </div>
        </nav>
        <Menu />
      </header>
    </>
  )
}

export default Header
