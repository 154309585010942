import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const SearchBox = ({ history }) => {
  const [keyword, setKeyword] = useState('')
  const [products, setProducts] = useState([])
  const [modelName, setModelName] = useState([])

  function searchProduct(e) {
    axios({
      method: 'get',
      url: `${apiUrl}/api/products?keyword=${e.target.value}`,
    }).then((res) => {
      setProducts(res.data.products)
      const uniqueModelNames = Array.from(
        new Set(res.data.products.map((item) => item.group_id))
      )
      setModelName(uniqueModelNames)
    })
  }

  const submitHandler = (e) => {
    const keywords = e.target.innerText
    const trimmedKeyword = keywords.trim()
    const searchKey = trimmedKeyword.replace(/ /g, '+')
    setKeyword(trimmedKeyword.replace(/ /g, '+'))
  }

  return (
    <Form
      onSubmit={submitHandler}
      inline
      className=' position-relative'
      autoComplete='off'
    >
      <Form.Control
        type='search'
        name='q'
        placeholder='Search Products...'
        className='mr-sm-2 ml-sm-5 search-box'
        onChange={(e) => searchProduct(e)}
        data-bs-toggle='dropdown'
        aria-expanded='false'
      ></Form.Control>
      <ul className='dropdown-menu w-100'>
        {modelName?.map((product) => (
          <>
            <li>
              <a className='dropdown-item' href={`/search?q=${keyword}`} onClick={submitHandler}>
                {product}
              </a>
            </li>
          </>
        ))}
      </ul>
    </Form>
  )
}

export default SearchBox
